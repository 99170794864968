import {
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  AppActionTypes,
  SAVE_NAME,
  SAVE_INPUT,
  SAVE_CATEGORY,
  ADD_TAGS,
  REMOVE_TAGS,
  GiftList,
  SET_ACTIVE_LIST,
  Product,
  SAVE_PRODUCTS,
  SAVE_PRODUCTS_COUNT,
  Category,
  SAVE_CATEGORIES,
  SAVE_STORES,
  Store,
  ContentActionTypes,
  SAVE_CHAT_HISTORY,
  UserDetails,
  SAVE_USER_DETAILS,
  ChatBlock,
  SAVE_CHAT_DATA,
  SAVE_CHAT_BLOCKS,
  ADD_NEW_LIST,
  InputField,
  ChatSettings,
  SAVE_SHOPPING_CENTRE,

  ShoppingCentre,
  SAVE_CAMPAIGN,
  SAVE_PRICE_GROUPS,
  PriceGroup,
} from "./types";

export function saveChatHistory(
  giftListId: string,
  history: {
    chatBlockId: string,
    answerId?: string
  }[]
): AppActionTypes {
  return {
    type: SAVE_CHAT_HISTORY,
    history,
    giftListId,
  };
}

export function setActiveList(giftListId: string): AppActionTypes {
  return {
    type: SET_ACTIVE_LIST,
    giftListId,
  };
}

export function addProduct(
  giftListId: string,
  productId: string
): AppActionTypes {
  return {
    type: ADD_PRODUCT,
    giftListId,
    productId,
  };
}

export function removeProduct(
  giftListId: string,
  productId: string
): AppActionTypes {
  return {
    type: REMOVE_PRODUCT,
    giftListId,
    productId,
  };
}

export function saveName(giftListId: string, name: string): AppActionTypes {
  return {
    type: SAVE_NAME,
    giftListId,
    name
  };
}


export function saveInput(giftListId: string, value: string, inputId: string): AppActionTypes {
  return {
    type: SAVE_INPUT,
    giftListId,
    value,
    inputId
  };
}


export function saveCategory(
  giftListId: string,
  category: string
): AppActionTypes {
  return {
    type: SAVE_CATEGORY,
    giftListId,
    category,
  };
}

export function savePriceGroups(
  giftListId: string,
  priceGroups: string[]
): AppActionTypes {
  return {
    type: SAVE_PRICE_GROUPS,
    giftListId,
    priceGroups,
  };
}

export function addTags(giftListId: string, tags: string[]): AppActionTypes {
  return {
    type: ADD_TAGS,
    giftListId,
    tags,
  };
}

export function removeTags(giftListId: string, tags: string[]): AppActionTypes {
  return {
    type: REMOVE_TAGS,
    giftListId,
    tags,
  };
}


export function addNewList(): AppActionTypes {
  return {
    type: ADD_NEW_LIST
  };
}

export function saveUserDetails(userDetails: UserDetails): AppActionTypes {
  return {
    type: SAVE_USER_DETAILS,
    userDetails,
  };
}

export function saveProducts(products: Product[]): ContentActionTypes {
  return {
    type: SAVE_PRODUCTS,
    products,
  };
}

export function saveProductsCount(count: number): ContentActionTypes {
  return {
    type: SAVE_PRODUCTS_COUNT,
    count
  };
}

export function saveCategories(categories: Category[]): ContentActionTypes {
  return {
    type: SAVE_CATEGORIES,
    categories,
  };
}

export function saveStores(stores: Store[]): ContentActionTypes {
  return {
    type: SAVE_STORES,
    stores,
  };
}

export function saveShoppingCentre(shoppingCentre: ShoppingCentre): ContentActionTypes {
  return {
    type: SAVE_SHOPPING_CENTRE,
    shoppingCentre
  }
}

export function saveCampaign(campaignId: string): ContentActionTypes {
  return {
    type: SAVE_CAMPAIGN,
    campaignId
  }
}

export function saveChatData(chatFlow: string[], inputFields: InputField[],priceGroups: PriceGroup[], chatSetting: ChatSettings): ContentActionTypes {
  return {
    type: SAVE_CHAT_DATA,
    chatFlow,
    inputFields,
    priceGroups,
    chatSetting,
  };
}

export function saveChatBlocks(chatBlocks: { [key: string]: ChatBlock }): ContentActionTypes {
  return {
    type: SAVE_CHAT_BLOCKS,
    chatBlocks
  };
}
