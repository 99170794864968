@use "../../styles/imports/variables" as vars;

@keyframes blink {
    50% {
        opacity: 1;
    }
}

.message {
    color: black;
    background-color: vars.$hero-colour-1;
    border-radius: 0 10px 10px 10px;
    padding: 12px 20px;
    font-size: vars.$body-size;
    line-height: vars.$body-line;
    font-weight: 500;

    .dot {
        display: inline-block;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background-color: black;
        opacity: 0.4;

        &:not(:last-child) {
            margin-right: 6px;
        }

        @for $i from 1 through 3 {
            &:nth-of-type(#{$i}) {
                animation-name: blink;
                animation-duration: 1s;
                animation-delay: $i * 0.3333s;
                animation-iteration-count: infinite;
            }
        }
    }
}
