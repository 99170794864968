export const PRODUCTS_COUNT=` 
query ProductsCount($retailerIds: [ItemId], $storeIds: [ItemId]) {
  items: _allProductsMeta(filter: {OR: [{stores: {exists: false}}, {stores: {anyIn: $storeIds}}], retailer: {in: $retailerIds}}) 
  {
    count
  }
}`

export const PRODUCTS_PAGINATION = `
query getProductsPagination($retailerIds: [ItemId], $storeIds: [ItemId], $skip:IntType ) {
  products: allProducts(first: 100, skip: $skip, orderBy: featured_DESC,filter: {OR: [{stores: {exists: false}}, {stores: {anyIn: $storeIds}}], retailer: {in: $retailerIds}}) {
    id
    name: productName
    price
    featured
    description: shortDescription
    image {
      url
      title
    }
    retailer {
      id
      title
      logo{
        url
        alt
        focalPoint{
          x
          y
        }
      }
    }
    stores{
      id
      displayName
    }
    shoppingOptions{
      id
      title
      value
    }
    tags
    {
      id
      title
    }
    categories
    {
      id
    }
    priceGroups
    {
      id
      title
      key
      position
    }
    campaigns{
      id
    }
  }
}`;

