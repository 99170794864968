export const CATEGORIES = `
query getCategories {
  categories: allCategories(first: 100) {
    id
    title
    value
    all
    personaName
    chatEntry{
      id
    }
    personaAvatar {
      url
      alt
      focalPoint {
        x
        y
      }
    }
  }
}

  `;