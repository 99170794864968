import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { StoreData, StoreRecord, StoreVars } from "../types/store";
import { STORES } from "../queries/stores";
import { saveStores } from "../store/actions";

export const useStores = (shoppingCentreId: string) => {
  const [fetchStores, { loading, error, data }] = useLazyQuery<StoreData, StoreVars>(gql(STORES));
  const dispatch = useDispatch();

  // This is the place to map the product model if we need to
  const mapStoreObject = (store: StoreRecord) => {
    return {
      retailerId: store.retailer.id, // Using retailerID in product to fetch the details - retailer Id is mandatory in products
      title: store.displayName || store.retailer.title, // use the stores specific name if exist otherwise the retailers title
      url:store.url || store.retailer.url,
      logo: store.retailer.logo,
      id: store.id, // needed to filter the products
    };
  };

  // Fetch the stores when we know the shopping center
  React.useEffect(() => {
    if (shoppingCentreId) {
     // console.log("fetching stores for", shoppingCentreId);
      fetchStores({ variables: { shoppingCentreId } });
    }
  }, [shoppingCentreId]);

  // Put the stores into the redux state
  React.useEffect(() => {
    if (data?.stores) {
     // console.log("fetched stores");
      dispatch(saveStores([...data.stores,...data.storesSecond].map((store) => mapStoreObject(store))));
    }
  }, [data]);

  return null;
};
