import React from 'react';
import {
    gql,
    useLazyQuery,
} from "@apollo/client";
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from '../store/selectors';
import { saveCategories } from '../store/actions';
import { CategoryData, CategoryRecord } from '../types/category';
import { CATEGORIES } from '../queries/categories';
import { Category } from '../store/types';

export const useCategories = () => {
    const [fetchCategories, { loading, error, data }] = useLazyQuery<CategoryData>(gql(CATEGORIES));
    const categories = useSelector(getCategories);
    const dispatch = useDispatch();

    const mapCategoryObject = (category: CategoryRecord): Category => {
        let item: Category = {
            id: category.id,
            title: category.title,
            value: category.value,
            personaName: category.personaName,
            personaAvatar: category.personaAvatar,
            chatEntry: category.chatEntry,
            selectAll: category.all
        };
        return item;
    };

    React.useEffect(() => {
        if (!categories?.length) {
          //  console.log('fetching categories');
            fetchCategories();
        }
    }, [categories]);

    React.useEffect(() => {
        if (data?.categories) {
           // console.log('fetched categories');
            dispatch(saveCategories(data.categories.map(category => mapCategoryObject(category))));
        }
    }, [data]);

    return categories;
};