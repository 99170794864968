@use "../../styles/imports/utils";

.productTile {
    border-radius: 10px;

    overflow: hidden;

    .image {
        background-color: white;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transition: transform 0.8s ease;
    }

    .logo {
        position: absolute;
        left: 10px;
        top: 10px;
    }

    .save {
        position: absolute;
        right: 10px;
        bottom: 10px;
    }

    .large-clickable-area {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        &:hover {
            .image {
                transform: scale(1.05);
            }
        }
    }
}
