@use "../../styles/imports/utils";
@use "../../styles/imports/variables" as vars;

.categories-element {
    .title {
        margin-left: 80px;
        margin-right: 80px;
        text-align: center;
    }

    .categories {
        display: flex;
        flex-wrap: wrap;
    }

    .category {
        width: calc(50% - 5px);
        border: none;
        background: none;
        margin-bottom: 20px;

        &:nth-child(2n) {
            margin-left: 10px;
        }

        &.active {
            color: black;
            background-color: vars.$hero-colour-2;
        }

        .image-container {
            height: 0;
            width: 100%;
            padding-top: 100%;
            position: relative;
        }

        .image {
            border-radius: 10px;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            @include utils.background-image();
            background-color: vars.$grey; // Just a backup for no image
        }
    }
}
