import React from 'react';
import * as classes from './header.module.scss';
import { Img } from '../../types/img';
import { utils } from '../../services/utils';

export const Header: React.FC<{ title: string, img: Img }> = ({ title, img }) => {
    const ref = React.useRef<HTMLDivElement>();
    const c = (img?.focalPoint?.x || 0.5) * 100;
    const z = 1702 / 455;
    const croppedImg = React.useMemo(() => utils.addQueryStringParams(img.url, [
        { name: 'h', value: '109' },
        { name: 'w', value: '142' },
        { name: 'fit', value: 'crop' },
        { name: 'crop', value: 'focalpoint' },
        { name: 'fp-x', value: `${img?.focalPoint?.x || 0.5}` },
        { name: 'fp-y', value: `${img?.focalPoint?.y || 0.5}` },
    ]), [img]);

    return (
        <div ref={ref} className={classes.header}>
            <img className={classes.image} src={croppedImg} />
            <h1 className={classes.title}>
                {title}
            </h1>
        </div>
    );
};