@use "../../styles/imports/utils";
@use "../../styles/imports/variables" as vars;

.icon-text-button {
    composes: icon-button from "../icon-button/icon-button.module.scss";
    height: unset;
    width: unset;
    padding: 5px 10px;
    border-radius: 15px;

    > * {
        &:not(:last-child) {
            margin-right: 8px;
        }
    }
}
