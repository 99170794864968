@use "../../styles/imports/variables" as vars;
@use "../../styles/imports/utils";

.input-element {
    position: relative;
    margin-left: auto;
    max-width: 300px;

    input {
        width: 100%;
        background: white;
        border: 2px solid white;
        border-radius: 10px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.07);
        padding: 12px;
        padding-right: 50px;
        color: vars.$brown;

        &:focus {
            border-color: vars.$hero-colour-2;
            outline: none;
        }
    }

    .submit {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
    }
}
