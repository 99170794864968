@use "../../styles/imports/utils";
@use "../../styles/imports/variables" as vars;

@keyframes bounce {
    0% {
        transform: none;
    }
    30% {
        transform: translateY(25%);
    }
    50% {
        transform: none;
    }
    100% {
        transform: none;
    }
}

.slider-element {
    .title {
        margin: 0 60px 24px 60px;
        text-align: center;
    }

    .slider {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .track {
        background: linear-gradient(to left, rgba(155, 155, 155, 0.44) 50%, #e5e5e5 50%);
        background-size: 200% 100%;
        display: flex;
        justify-content: space-around;
        border-radius: 10px;
        width: 100%;
        height: 25px;
        position: relative;
        margin: 0 20px;
    }

    .level {
        height: 25px;
        width: 25px;
        background: none;
        border-radius: 10px;
        border: none;
        overflow: hidden;
        padding: 0;

        &::after {
            content: "";
            display: block;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            background-color: white;
            transform: scale(0.4);
        }
    }

    .detail {
        text-align: center;
        margin: 40px 0 20px 0;

        h3 {
            font-size: 14px;
            font-weight: vars.$font-weight-bolder;
        }

        p {
            margin: 0 20px;
        }
    }

    .indicator {
        height: 25px;
        width: 25px;
        background-color: black;
        border-radius: 10px;
        position: absolute;
        left: 0;
        top: 0;
        cursor: pointer;

        svg {
            position: absolute;
            top: 100%;
            margin-top: 4px;

            animation-name: bounce;
            animation-timing-function: ease;
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }
    }
}

@media screen and (min-width: 455px) {
    .slider-element {
        .detail {
            margin: 45px 0 35px 0;

            p {
                margin: 0 80px;
            }
        }
    }
}
