import React from 'react';
import {
    gql,
    useLazyQuery,
} from "@apollo/client";
import { useDispatch, useSelector } from 'react-redux';
import { getChatBlocks } from '../store/selectors';
import { saveChatBlocks } from '../store/actions';
import { CHAT_BLOCKS } from '../queries/chat-blocks';
import { ChatBlockData, ChatBlockRecord, ChatBlockVars } from '../types/chat-block';
import { ChatBlockLookup, MessageTextType } from '../store/types';


export const useChatBlocks = (blockIds: string[]) => {
    const [fetchChatBlocks, { loading, error, data }] = useLazyQuery<ChatBlockData, ChatBlockVars>(gql(CHAT_BLOCKS));
    const chatBlocks = useSelector(getChatBlocks);
    const dispatch = useDispatch();

    const mapChatBlocks = (chatBlocks: ChatBlockRecord[]): ChatBlockLookup => {
        // Creates a look up by id of the chatblock objects
        return Object.assign({}, ...(chatBlocks || [])?.map((x) => {
            return {
                [x.id]: {
                    messages: x.messages.map(({ text }) => {
                        return {
                            text: text.value.document.children?.[0]?.children.map(({ type, value, item }) => {
                                if (type === "inlineItem") return { type: MessageTextType.InlineItem, item }
                                return { type: MessageTextType.Span, value };
                            })
                        };
                    }),
                    element: x.elements?.[0]
                }
            };
        }));
    };

    React.useEffect(() => {
        if (blockIds?.length > 0) {
            // console.log('fetching chat blocks');
            fetchChatBlocks({ variables: { blockIds } });
        }
    }, [blockIds]);

    React.useEffect(() => {
        if (data) {
            // console.log('saving chat blocks');
            dispatch(saveChatBlocks(mapChatBlocks([...data.chatBlocks, ...data.chatBlocksSecond])));
        }
    }, [data]);

    return chatBlocks;
};