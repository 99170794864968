.modal {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(black, 0.4);
    overflow: auto;
    z-index: 10;

    .modal-inner {
        position: relative;
        margin: 0 15px;
        padding: 50px 0 15px 0;

        .close {
            position: absolute;
            top: 8px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.modal-enter {
    opacity: 0;
    .modal-inner {
        transform: scale(0.9);
    }
}
.modal-enter-active {
    opacity: 1;
    .modal-inner {
        transform: translateX(0);
        transition: transform 300ms;
    }
    transition: opacity 300ms;
}
.modal-exit {
    opacity: 1;
}
.modal-exit-active {
    opacity: 0;
    .modal-inner {
        transform: scale(0.9);
        transition: transform 300ms;
    }
    transition: opacity 300ms;
}
