import React from 'react';
import * as classes from './categories-element.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { saveCategory } from '../../store/actions';
import { getActiveListId, getValidCategories } from '../../store/selectors';
import { Img } from '../../types/img';
import { utils } from '../../services/utils';

export const CategoriesElement: React.FC<{ heading: string, answerId: string, onSelect: (action: string, value: string) => void }> = ({ heading, answerId, onSelect }) => {
    const categories = useSelector(getValidCategories);
    const giftListId = useSelector(getActiveListId);
    const dispatch = useDispatch();

    const handleSelect = (action: string, value: string, title: string) => {
        utils.createGtmEvent('category-selected', { value: title });
        dispatch(saveCategory(giftListId, value));
        onSelect(action, value);
    };

    const getCroppedImg = (img: Img) => {
        return utils.addQueryStringParams(img?.url, [
            { name: 'h', value: '187' },
            { name: 'w', value: '187' },
            { name: 'fit', value: 'crop' },
            { name: 'crop', value: 'focalpoint' },
            { name: 'fp-x', value: `${img?.focalPoint?.x || 0.5}` },
            { name: 'fp-y', value: `${img?.focalPoint?.y || 0.5}` },
        ]);
    };

    if (!categories?.length) return null;

    return (
        <div className={classes['categories-element']}>
            <h2 className={classes.title}>{heading}</h2>
            <div className={classes.categories}>
                {categories.map(({ id, title, personaAvatar, chatEntry }) => {
                    return (
                        <button key={id}
                            className={classes.category + (id === answerId ? ` active` : '')}
                            onClick={() => handleSelect(chatEntry?.id, id, title)}>
                            <div className={classes['image-container']}>
                                <div className={classes.image} style={{ backgroundImage: `url(${getCroppedImg(personaAvatar)})` }} />
                            </div>
                            <small>{title}</small>
                        </button>
                    );
                })}
            </div>
        </div>
    );
};