@use "../../styles/imports/variables" as vars;

.google-sign-in {
    width: 100%;
    background: none;
    border: 2px solid #C4C4C4;
    border-radius: 6px;
    color: vars.$blue-grey;
    font-size: 16px;
    line-height: 24px;
    padding: 11px 16px;
    position: relative;

    svg {
        position: absolute;
        left: 16px;
    }
}
