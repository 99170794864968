import React from 'react';
import * as classes from './results.module.scss';
import { ProductTile } from '../product-tile/product-tile';
import { useSelector } from 'react-redux';
import { getIsCategoryBased, getResults } from '../../store/selectors';
import Chevron from '../../assets/svgs/chevron-down.svg';
import { useIntersection } from '../../hooks/use-intersection';
import { ChatBlockMoveAction } from '../../store/types';


export const Results: React.FC<{ isActive: boolean, skip?: number, take?: number, otherCategories?: boolean, filterByTags?: boolean, noResultsMessage?: string, onReachedEnd: (action?: string) => void }> = ({ isActive, skip, take, otherCategories, filterByTags, noResultsMessage, onReachedEnd }) => {
    const isCategoryBased = useSelector(getIsCategoryBased); // show filter if it's category based
    const products = useSelector(getResults(skip, take, otherCategories, filterByTags));
    const [selectedPrice, setSelectedPrice] = React.useState<string>();
    const bottomMarkerRef = React.useRef<HTMLDivElement>();
    const { isIntersecting: reachedEnd } = useIntersection(bottomMarkerRef, { rootMargin: '-20% 0%' }, false);

    let filters = !isCategoryBased || [].concat.apply([], products.map(({ priceGroups }) => priceGroups))
        .filter((priceGroup, index, list) => list.map(({ id }) => id).indexOf(priceGroup.id) === index)
        .sort((item1, item2) => item1.position - item2.position)
        .map(({ id, title }) => ({ text: title, value: id }));

    let filteredProducts = selectedPrice ? products.filter(product => product.priceGroups.find(({ id }) => id === selectedPrice)) : products;

    const handleFilter = (value: string) => {
        setSelectedPrice(value);
    };

    React.useEffect(() => {
        let timer;
        if (reachedEnd && isActive) {
            setTimeout(() => {
                onReachedEnd();
            }, 1000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [reachedEnd, isActive]);

    return (
        <div className={classes.results}>
            {products?.length ?
                <React.Fragment>
                    {!!isCategoryBased && <div className={classes.filter}>
                        <select onChange={(event) => handleFilter(event.target.value)}>
                            <option value="">Select a price group</option>
                            {filters.map(({ text, value }) => {
                                return (
                                    <option key={`filter-option-${value}`} value={value}>{text}</option>
                                );
                            })}
                        </select>
                        <div className={classes.chevron}><Chevron /></div>
                    </div>}
                    <div className={classes.productsGrid}>
                        {filteredProducts.map((product, index) => {
                            return (
                                <div key={`product-${index}`}>
                                    <ProductTile product={product} />
                                </div>
                            );
                        })}
                    </div>
                </React.Fragment>
                :
                <div className={classes['no-results']}>{noResultsMessage || 'No results'}</div>
            }


            <div ref={bottomMarkerRef} />
        </div>
    );
};