import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveUserDetails } from '../../store/actions';
import { getDefaultEdmBanner, getDefaultIcon, getGiftListsFull, getShoppingCentre, getUserDetails } from '../../store/selectors';
import { AvatarHeading } from '../avatar-heading/avatar-heading';
import * as classes from './send-list-form.module.scss';
import Check from '../../assets/svgs/check.svg';
import { GoogleSignIn } from '../google-sign-in/google-sign-in';
import ReCAPTCHA from "react-google-recaptcha";
import { utils } from '../../services/utils';

const enum SubmitState {
    NotSubmitted = "not-submitted",
    Submitting = "submitting",
    Submitted = "submitted",
    Error = "error"
};

export const SendListForm: React.FC<{ onCancel: () => void }> = ({ onCancel }) => {
    const userDetails = useSelector(getUserDetails);
    const icon = useSelector(getDefaultIcon);
    const edmBanner = useSelector(getDefaultEdmBanner);
    const dispatch = useDispatch();
    const [firstName, setFirstName] = React.useState<string>(userDetails?.firstName || "");
    const [lastName, setLastName] = React.useState<string>(userDetails?.lastName || "");
    const [email, setEmail] = React.useState<string>(userDetails?.email || "");
    const [mobile, setMobile] = React.useState<string>(userDetails?.mobile || "");
    const [postcode, setPostcode] = React.useState<string>(userDetails?.postcode || "");
    const [subscribe, setSubscribe] = React.useState<boolean>(false);
    const [submitState, setSubmitState] = React.useState<SubmitState>(SubmitState.NotSubmitted);
    const [dirty, setDirty] = React.useState<boolean>(false);
    const [isPrivacyOpen, setIsPrivacyOpen] = React.useState<boolean>(false);
    const lists = useSelector(getGiftListsFull);
    const shoppingCentre = useSelector(getShoppingCentre);
    const recaptchaRef = React.createRef<HTMLDivElement>();

    const headings = {
        'not-submitted': { heading: 'We are sending your list to your email', text: ' ' },
        'submitting': { heading: 'We are sending your list to your email', text: 'Please wait...' },
        'submitted': { heading: 'Wonderful!', text: "Your list is on its way and these gifts will be sure to get you into Mum’s good books!" },
        'error': { heading: 'There was an error', text: 'Please try again' },
    };

    const handleGoogleSignIn = (firstName: string, lastName: string, email: string) => {
        dispatch(saveUserDetails({ firstName, lastName, email }));
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        setSubmitState(SubmitState.Submitting);
        if (firstName && lastName && email && postcode && (!subscribe || mobile)) {
            const token = await recaptchaRef.current.executeAsync();
            const submissionStatus = await submitForm(token);
            if (submissionStatus === 200) {
                dispatch(saveUserDetails({ firstName, lastName, email, postcode, mobile }));
                setSubmitState(SubmitState.Submitted);
                utils.createGtmEvent('form-submit', { value: 'Success' });
            }
            else {
                setSubmitState(SubmitState.Error);
                utils.createGtmEvent('form-submit', { value: 'Error' });
            }
        }
    };

    const handleSubscribe = (event) => {
        setSubscribe(event.target.checked);
        utils.createGtmEvent('subscribe-clicked', { value: event.target.checked });
    };

    const submitForm = async (token) => {
        const reqBody = {
            firstName,
            lastName,
            email,
            mobile,
            postcode,
            subscribe,
            lists: lists.map(list => ({
                id: list.id,
                name: list.name,
                category: { title: list.category?.title, persona: list.category?.personaName },
                products: list.products.map(product => ({
                    name: product.name,
                    description: product.description,
                    price: product.price,
                    retailer: { title: product.retailer?.title, url: product.retailer?.url },
                    image: { ...product.image },
                    clickCollect: product.shoppingOptions?.find(option => (option.value.trim().toLowerCase() == "click-collect"))
                }))
            })),
            shoppingCentre,
            token,
            campaignKey: process.env.CAMPAIGN_KEY,
            banner: edmBanner?.url
        };

        const response = await fetch(`${process.env.SUBMIT_API}/submit`, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(reqBody)
        });

        return response.status;
    };

    React.useEffect(() => {
        if (userDetails) {
            setFirstName(userDetails.firstName);
            setLastName(userDetails.lastName);
            setEmail(userDetails.email);
            setMobile(userDetails.mobile);
        }
    }, [userDetails]);

    return (
        <div className={classes['send-list-form']}>

            <AvatarHeading
                img={icon}
                heading={headings[submitState].heading}
                text={headings[submitState].text} />

            {submitState !== SubmitState.Submitted &&
                <div className={classes.form + (submitState === SubmitState.Submitting ? ` ${classes.pending}` : '')}>
                    <GoogleSignIn onSignIn={handleGoogleSignIn} />

                    <div className={classes.divider}><span>OR</span></div>

                    <form onSubmit={handleSubmit} aria-label="Save list" className={dirty ? classes.dirty : ''}>

                        <div className={classes["input-field"]}>
                            <label htmlFor="send-list-form-first-name" className="sr-only">Name</label>
                            <input
                                type="text"
                                id="send-list-form-first-name"
                                name="send-list-form-first-name"
                                value={firstName}
                                onChange={(event) => setFirstName(event.target.value)}
                                required
                                placeholder="First Name" />
                            <Check className={classes.tick} />
                        </div>
                        <div className={classes["input-field"]}>
                            <label htmlFor="send-list-form-last-name" className="sr-only">Last Name</label>
                            <input
                                type="text"
                                id="send-list-form-last-name"
                                name="send-list-form-last-name"
                                value={lastName}
                                onChange={(event) => setLastName(event.target.value)}
                                required
                                placeholder="Last Name" />
                            <Check className={classes.tick} />
                        </div>
                        <div className={classes["input-field"]}>
                            <label htmlFor="send-list-form-email" className="sr-only">Email Address</label>
                            <input
                                type="email"
                                id="send-list-form-email"
                                name="send-list-form-email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                required
                                placeholder="Email Address" />
                            <Check className={classes.tick} />
                        </div>
                        <div className={classes["input-field"]}>
                            <label htmlFor="send-list-form-postcode" className="sr-only">Postcode</label>
                            <input
                                type="text"
                                id="send-list-form-postcode"
                                name="send-list-form-postcode"
                                value={postcode}
                                onChange={(event) => setPostcode(event.target.value)}
                                required
                                pattern="[0-9]*"
                                placeholder="Postcode" />
                            <Check className={classes.tick} />
                        </div>
                        {!!subscribe && <div className={classes["input-field"]}>
                            <label htmlFor="send-list-form-mobile" className="sr-only">Mobile Number</label>
                            <input
                                type="tel"
                                pattern="[0-9]{10}"
                                id="send-list-form-mobile"
                                name="send-list-form-mobile"
                                value={mobile}
                                onChange={(event) => setMobile(event.target.value)}
                                required
                                placeholder="Mobile Number" />
                            <Check className={classes.tick} />
                        </div>}
                        <div className={classes["checkbox-field"]}>
                            <input
                                type="checkbox"
                                id="send-list-form-subscribe"
                                name="send-list-form-subscribe"
                                checked={subscribe}
                                onChange={handleSubscribe} />
                            <label htmlFor="send-list-form-subscribe">
                                <small>
                                    Yes, I would like to subscribe to the newsletter to receive the latest news, rewards, discounts and more!
                                </small>
                            </label>
                        </div>


                        <div className={classes.privacy}>
                            <button type="button" className={classes['privacy-toggle']} onClick={() => setIsPrivacyOpen(!isPrivacyOpen)}>
                                Privacy collection statement
                            </button>
                            <div style={{ display: isPrivacyOpen ? 'block' : 'none' }}>
                                <p>The Mirvac Group (Mirvac Limited and its controlled entities) collects and uses personal information about you to provide you with information you request from Mirvac. Mirvac also uses your personal information for related purposes including to request your feedback on the products and services provided by Mirvac. If you do not provide all the personal information Mirvac requests from you, Mirvac may be unable to provide these products or services to you.</p>

                                <p>Mirvac may disclose personal information about you to third party agents and service providers to assist Mirvac in the operation of its business to provide the products and services you request. Your personal information may also be disclosed to overseas recipients who use the personal information to assist Mirvac in the operation of its business in countries including, but not limited to, [Australia, Japan, United States and United Kingdom] </p>

                                <p>The Mirvac Group Privacy Policy (available on www.mirvac.com) contains information about how you may request access to and correction of personal information Mirvac holds about you, or to make a complaint about an alleged breach of the Australian Privacy Principles. You can also contact our Mirvac Privacy Officer using the following details: Phone: (02) 9080 8000; Email: privacy@mirvac.com; and Address: 'Mirvac Privacy Officer', Mirvac Group Compliance, Level 28, 200 George Street, Sydney, NSW 2000.</p>

                                <p>Promotion of other products and services<br />
                                    By submitting this form, you agree to Mirvac using your personal information to promote its products and services. If you no longer wish to receive promotional information from Mirvac, you may advise Mirvac of your wish. The Mirvac Group Privacy Policy contains information about how you may send your request to Mirvac.</p>
                            </div>
                        </div>


                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey={process.env.RECAPTCHA_SITE_KEY}
                        />

                        <div className={classes.actions}>
                            <button className={classes.secondary} type="button" onClick={onCancel}>Cancel</button>
                            <button className={classes.primary} type="submit" onClick={() => setDirty(true)}>Send</button>
                        </div>
                    </form>
                </div>
            }
        </div>

    );
};