import React from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { saveShoppingCentre } from "../store/actions";
import {ShoppingCentreData, ShoppingCentreRecord, ShoppingCentreVars } from "../types/shopping-centre";
import { SHOPPING_CENTRE } from "../queries/shopping-centre";
import { getShoppingCentre } from "../store/selectors";
import { ShoppingCentre } from "../store/types";

export const useShoppingCentre = (shoppingCentreKey: string) => {

  const [fetchShoppingCentre, { data }] = useLazyQuery<ShoppingCentreData, ShoppingCentreVars>(gql(SHOPPING_CENTRE));
  const shoppingCentre = useSelector(getShoppingCentre);
  const dispatch = useDispatch();

  const mapShoppingCentreData = (centre: ShoppingCentreRecord): ShoppingCentre => {

    let baseLink= centre.websiteLink || `https://www.${centre.key}.com.au/`;
    let formSetting = centre.formAssemblySettings?.find(setting => setting.environment === process.env.FA_ENV);

    return {
      id: centre.id,
      title: centre.title,
      key: centre.key,
      emailSender: centre.emailSender,
      emailReplyTo: centre.emailReplyTo,
      edmBanner: centre.edmBanner,
      subscribe :{
        id: formSetting?.id,
        dbControl :formSetting?.dbControl
      },
      websiteLink: baseLink,
      storesLink: centre.storesLink || `${baseLink}stores`,
      openingHoursLink: centre.openingHoursLink || `${baseLink}hours`,
      blogLink: centre.blogLink || `${baseLink}whats-on/inspiration`,
      facebook: centre.facebook ,
      instragram: centre.instragram,
      address: centre.address,
      tripadvisor: centre.tripadvisor,
      logoLink: centre.logoLink
    }
  };

  React.useEffect(() => {
    if (!shoppingCentre && shoppingCentreKey) {
     // console.log("fetching shoppingCentre for", shoppingCentreKey);
      fetchShoppingCentre({ variables: { shoppingCentreKey: shoppingCentreKey } });
    }
  }, [shoppingCentre, shoppingCentreKey]);

  React.useEffect(() => {
    if (data?.shoppingCentre) {
     // console.log("fetched shoppingCentre");
      dispatch(saveShoppingCentre(mapShoppingCentreData(data.shoppingCentre)));
    }
  }, [data]);

  return shoppingCentre;
};
