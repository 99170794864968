import React from 'react';
import * as classes from './pop-up.module.scss';
import XIcon from '../../assets/svgs/x.svg';
import { IconButton } from '../icon-button/icon-button';
import { CSSTransition } from 'react-transition-group';


export const PopUp: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isOpen, setIsOpen] = React.useState<boolean>();
    const reactAppRootEl = document.getElementById(process.env.GIFT_FINDER_ELEMENT_ID);
    let timeout;

    const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
            if (mutation.type === 'attributes' && mutation.attributeName === "class") {
                let open = reactAppRootEl.classList.contains("is-open");
                setIsOpen(open);
            }
        });

    });

    const onClose = () => {
        setIsOpen(false);
        timeout = setTimeout(() => {
            document.getElementById(process.env.GIFT_FINDER_ELEMENT_ID).classList.remove("is-open");
        }, 300);
    };

    React.useEffect(() => {
        observer.observe(reactAppRootEl, { attributes: true });
        return () => {
            observer.disconnect();
            clearTimeout(timeout);
        };
    }, []);

    return (
        <CSSTransition
            in={isOpen}
            timeout={300}
            classNames={{
                enter: classes['popup-enter'],
                enterActive: classes['popup-enter-active'],
                exit: classes['popup-exit'],
                exitActive: classes['popup-exit-active'],
            }}
            mountOnEnter
        >
            <div className={classes.popup} id="gift-guide-popup">
                {children}
                <IconButton className={classes.close} onClick={onClose}><XIcon /></IconButton>
            </div>
        </CSSTransition>
    );
};