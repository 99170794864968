import {
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  AppState,
  AppActionTypes,
  SAVE_NAME,
  SAVE_INPUT,
  SAVE_CATEGORY,
  ADD_TAGS,
  REMOVE_TAGS,
  SET_ACTIVE_LIST,
  SAVE_PRODUCTS,
  SAVE_PRODUCTS_COUNT,
  SAVE_CATEGORIES,
  SAVE_STORES,
  ContentState,
  ContentActionTypes,
  SAVE_CHAT_HISTORY,
  SAVE_USER_DETAILS,
  SAVE_CHAT_DATA,
  SAVE_CHAT_BLOCKS,
  ADD_NEW_LIST,
  SAVE_SHOPPING_CENTRE,
  SAVE_CAMPAIGN,
  SAVE_PRICE_GROUPS
} from "./types";
import { combineReducers } from "redux";
import { v4 as uuidv4 } from 'uuid';

const initialAppState: AppState = {
  giftLists: [],
  activeListId: null,
  userDetails: null
};

export function appReducer(
  state = initialAppState,
  action: AppActionTypes
): AppState {
  switch (action.type) {
    case SAVE_CHAT_HISTORY:
      return {
        ...state,
        giftLists: state.giftLists.map((list) =>
          list.id === action.giftListId
            ? { ...list, chatHistory: [...action.history] }
            : list
        ),
      };
    case SET_ACTIVE_LIST:
      return {
        ...state,
        activeListId: action.giftListId,
      };
    case ADD_PRODUCT:
      return {
        ...state,
        giftLists: state.giftLists.map((list) =>
          list.id === action.giftListId
            ? { ...list, products: [...list.products, action.productId] }
            : list
        ),
      };
    case REMOVE_PRODUCT:
      return {
        ...state,
        giftLists: state.giftLists.map((list) =>
          list.id === action.giftListId
            ? {
              ...list,
              products: list.products.filter(
                (product) => product !== action.productId
              ),
            }
            : list
        ),
      };
    case SAVE_NAME:
      return {
        ...state,
        giftLists: state.giftLists.map((list) =>
          list.id === action.giftListId ? { ...list, name: action.name } : list
        ),
      };
    case SAVE_INPUT:
      let newInput = {};
      newInput[action.inputId] = action.value;
      return {
        ...state,
        giftLists: state.giftLists.map((list) =>
          list.id === action.giftListId ? { ...list, inputs: { ...list.inputs, ...newInput } } : list
        ),
      };
    case SAVE_CATEGORY:
      return {
        ...state,
        giftLists: state.giftLists.map((list) =>
          list.id === action.giftListId
            ? { ...list, category: action.category, tags: [] } // Reset the tags when a category selected/changed
            : list
        ),
      };
    case SAVE_PRICE_GROUPS:
      return {
        ...state,
        giftLists: state.giftLists.map((list) =>
          list.id === action.giftListId
            ? { ...list, priceGroups: action.priceGroups }
            : list
        ),
      };
    case ADD_TAGS:
      return {
        ...state,
        giftLists: state.giftLists.map((list) =>
          list.id === action.giftListId
            ? {
              ...list,
              tags: [...list.tags, ...action.tags].filter(
                (v, i, a) => a.indexOf(v) === i
              ),
            }
            : list
        ),
      };
    case REMOVE_TAGS:
      return {
        ...state,
        giftLists: state.giftLists.map((list) =>
          list.id === action.giftListId
            ? {
              ...list,
              tags: list.tags.filter((tag) => !action.tags.includes(tag)),
            }
            : list
        ),
      };
    case SAVE_USER_DETAILS:
      return {
        ...state,
        userDetails: action.userDetails,
      };
    case ADD_NEW_LIST:
      let newList = {
        id: uuidv4(),
        name: '',
        category: '',
        tags: [],
        priceGroups: [],
        products: [],
        chatAnswers: [],
        chatHistory: [],
        inputs: {}
      };
      return {
        ...state,
        giftLists: [...state.giftLists, newList],
        activeListId: newList.id
      };
    default:
      return state;
  }
}

const initialContentState: ContentState = {
  products: [],
  categories: [],
  stores: [],
  chatData: null,
  shoppingCentre: null,
  productsCount: 0,
  campaignId: null
};

export function contentReducer(
  state = initialContentState,
  action: ContentActionTypes
): ContentState {
  switch (action.type) {
    case SAVE_PRODUCTS:
      return {
        ...state,
        products: action.products,
      };
    case SAVE_PRODUCTS_COUNT:
      return {
        ...state,
        productsCount: action.count,
      };
    case SAVE_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };
    case SAVE_STORES:
      return {
        ...state,
        stores: action.stores,
      };
    case SAVE_SHOPPING_CENTRE:
      return {
        ...state,
        shoppingCentre: action.shoppingCentre,
      };
    case SAVE_CAMPAIGN:
      return {
        ...state,
        campaignId: action.campaignId,
      };
    case SAVE_CHAT_DATA:

      var chatDataObj = {
        ...state.chatData,
        chatFlow: action.chatFlow,
        inputFields: action.inputFields,
        priceGroups: action.priceGroups,
        chatSetting: action.chatSetting
      }
      return {
        ...state,
        chatData: chatDataObj
      };
    case SAVE_CHAT_BLOCKS:
      var chatDataObj = {
        ...state.chatData,
        chatBlocks: action.chatBlocks
      }

      return {
        ...state,
        chatData: chatDataObj
      };
    default:
      return state;
  }
}

export const rootReducer = combineReducers({
  app: appReducer,
  content: contentReducer
});

export type RootState = ReturnType<typeof rootReducer>;
