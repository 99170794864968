@use "../../styles/imports/variables" as vars;
@use "../../styles/imports/utils";

.send-list-form {
    padding-top: 70px;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 286px;
    margin: 0 auto;
    justify-content: space-between;

    .divider {
        color: vars.$neutral-grey;
        font-size: 12px;
        line-height: 18px;
        font-weight: 600;
        text-align: center;
        position: relative;
        margin: 24px 0;

        span {
            background: vars.$light-pink;
            border-radius: 24px;
            padding: 4px 14px;
        }

        &:before {
            content: "";
            position: absolute;
            background: vars.$light-blue-grey;
            height: 1px;
            width: 100%;
            left: 0;
            top: 50%;
            z-index: -1;
        }
    }

    .form {
        padding: 18px 0 90px 0;
        transition: opacity 0.3s;

        &.pending {
            opacity: 0.6;
            pointer-events: none;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .input-field {
            position: relative;
            margin-bottom: 12px;
        }

        .tick {
            position: absolute;
            right: 19px;
            top: 50%;
            transform: translateY(-50%);
            display: none;
        }

        input[type="text"],
        input[type="email"],
        input[type="tel"] {
            border: 1px solid vars.$grey;
            border-radius: 10px;
            color: vars.$brown;
            padding: 12px 16px;
            padding-right: 45px;
            width: 100%;

            &::-ms-clear {
                display: none;
            }

            &:focus {
                border: 2px solid vars.$hero-colour-1;
                padding: 11px 15px;

                outline: none;
            }

            // &:not(:focus):invalid {
            //     border: 2px solid vars.$lighter-red;
            //     padding: 11px 15px;
            // }

            &:not(:focus):valid {
                ~ .tick {
                    display: block;
                }
            }
        }

        .checkbox-field {
            position: relative;
            margin-bottom: 12px;
        }

        input[type="checkbox"] {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            ~ label {
                padding: 0 0 0 40px;
                margin: 0;
                position: relative;
                display: inline-block;

                &:before {
                    content: "";
                    border-radius: 6px;
                    left: 0;
                    top: 0;
                    width: 22px;
                    height: 22px;
                    border: 1px solid vars.$grey;
                    background-color: vars.$light-grey;
                    position: absolute;
                    display: block;
                    cursor: pointer;
                }
            }
            &:focus ~ label:before {
                border-color: vars.$hero-colour-1;
                border-width: 2px;
            }
            &:checked ~ label {
                &:after {
                    content: "";
                    display: block;
                    width: 9px;
                    height: 5px;
                    border-right: 2px solid vars.$brown;
                    border-top: 2px solid vars.$brown;
                    transform: rotate(135deg);
                    position: absolute;
                    left: 6px;
                    top: 7px;
                }
            }
        }

        &.dirty {
            input[type="text"],
            input[type="email"],
            input[type="tel"] {
                &:not(:focus):invalid {
                    border: 2px solid vars.$lighter-red;
                    padding: 11px 15px;
                }
            }

            input[type="checkbox"] {
                &:not(:focus):invalid {
                    ~ label:before {
                        border-color: vars.$lighter-red;
                        border-width: 2px;
                    }
                }
            }
        }
    }

    .actions {
        margin-top: auto;
        padding-top: 40px;
        display: flex;
        justify-content: space-between;

        .primary {
            border: 1px solid vars.$darker-grey;
            background: none;
            padding: 12px 18px;
            border-radius: 10px;
            color:vars.$black;
        }

        .secondary {
            border: none;
            text-decoration: underline;
            background: none;
            padding: 0;
            color: vars.$blue-grey;
        }
    }

    .privacy {
        font-size: 14px;
        margin-top: 20px;

        .privacy-toggle {
            text-decoration: underline;
            background: none;
            border: none;
            padding: 0;
            font-size: 14px;
            margin-bottom: 10px;
        }
    }
}

@media screen and (min-width: 455px) {
    .send-list-form {
        .form {
            padding: 18px 0;
        }
    }
}
