import React from 'react';
import * as classes from './persona-header.module.scss';
import { Img } from '../../types/img';
import { utils } from '../../services/utils';

export const PersonaHeader: React.FC<{ title: string, avatar: Img, minimised: boolean }> = ({ title, avatar, minimised }) => {
    const ref = React.useRef<HTMLDivElement>();
    const c = (avatar?.focalPoint?.x || 0.5) * 100;
    const z = 1702 / 455;
    const croppedImg = React.useMemo(() => utils.addQueryStringParams(avatar.url, [
        { name: 'h', value: '472' },
        { name: 'w', value: '1702' },
        { name: 'fit', value: 'crop' },
        { name: 'crop', value: 'focalpoint' },
        { name: 'fp-x', value: `${avatar?.focalPoint?.x || 0.5}` },
        { name: 'fp-y', value: `${avatar?.focalPoint?.y || 0.5}` },
    ]), [avatar]);

    return (
        <div ref={ref} className={classes['persona-header'] + (minimised ? '' : ` ${classes['persona-header-maximised']}`)}>
            <div className={classes.background}
                style={{
                    backgroundImage: `url(${croppedImg})`,
                    backgroundPositionX: `${((c) - 50) * z / (z - 1) + 50}%`,
                    //backgroundPositionY: `${(avatar?.focalPoint?.y || 0.5) * 100}%`,
                }} />
            <div className={classes['title-container']}>
                <h1 className={classes.title}>
                    {title.split(' ').map((word, index) => word.toLowerCase() === "the" ? <span key={`word-${index}`} className={classes['title-small']}>{word}</span> : <span key={`word-${index}`}>{word}</span>)}
                </h1>
            </div>
        </div>
    );
};