import React from 'react';
import ReactDOM from 'react-dom';

export const Portal: React.FC<{ children: React.ReactNode, portalId: string }> = ({ children, portalId }) => {
    let popupRoot = document.getElementById('gift-guide-popup');
    let portalRoot = popupRoot.querySelector(`#${portalId}`);

    if (!portalRoot) {
        portalRoot = document.createElement("div");
        portalRoot.setAttribute("id", portalId);
        popupRoot.appendChild(portalRoot);
    }

    return ReactDOM.createPortal(children, portalRoot);
};