import {  InMemoryCache, makeVar} from "@apollo/client";

//export const chatValuesVar = makeVar([]);

export const cache = new InMemoryCache(
//     {
//     typePolicies: {
//         // ChatBlock: {
//         //     fields: {
//         //         selectedOption: {
//         //             read(_, {variables}) {
//         //                 return localStorage.getItem(`chat-block-${variables.chatBlockId}`);
//         //             }
//         //         }
//         //     }
//         // }
//         Query: {
//             fields: {
//                 chatValues: {
//                     read() {
//                         return chatValuesVar();
//                     }
//                 }
//             }
//         }
//     }
// }
);
 