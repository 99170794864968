import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveList, getIsCategoryBased, getGiftListsFull } from '../../store/selectors';
import { Drawer } from '../drawer/drawer';
import { GiftList } from '../gift-list/gift-list';
import * as classes from './dashboard.module.scss';
import HeartIcon from '../../assets/svgs/heart.svg';
import { addNewList, setActiveList } from '../../store/actions';
import { Screen } from '../screen/screen';
import { SendListForm } from '../send-list-form/send-list-form';
import { ChatFlowType } from '../../store/types';

export const Dashboard: React.FC = () => {
    const lists = useSelector(getGiftListsFull);
    const isCategoryBased = useSelector(getIsCategoryBased);
    const activeList = useSelector(getActiveList);
    const [isOpen, setIsOpen] = React.useState<boolean>();
    const [isFormOpen, setIsFormOpen] = React.useState<boolean>();
    const dispatch = useDispatch();
    // If category based, a category being selected on a list is the requirement for making the dashoboard available
    const showDashboardButton = (!isCategoryBased || lists?.find(list => list.category)) && !isOpen;

    const handleAddList = () => {
        // close dashboard
        setIsOpen(false);
        // add empty list to state
        dispatch(addNewList());
    };

    const handleSelectList = (listId: string) => {
        // close dashboard
        setIsOpen(false);
        // activiate this list
        if (activeList.id !== listId) dispatch(setActiveList(listId));
    };

    const handleSaveList = () => {
        // close dashboard
        setIsOpen(false);
        // open form
        setIsFormOpen(true);
    };

    return (
        <React.Fragment>
            {showDashboardButton ?
                <button className={classes['dashboard-button']} onClick={() => setIsOpen(true)}>
                    <HeartIcon className={classes.heart} />
                    <small>My list {activeList?.products?.length ? <strong>({activeList.products.length})</strong> : ''}</small>
                </button> :
                null
            }

            <Drawer show={isOpen} onClose={() => setIsOpen(false)}>
                <div className={classes.dashboard}>
                    <div className={classes.header}>
                        <HeartIcon className={classes.heart} />
                        <h2>My list</h2>
                    </div>
                    <div className={classes.content}>
                        {lists.map(({ id, name, category, products }) => {
                            return (
                                <GiftList key={id}
                                    id={id}
                                    name={name}
                                    category={category}
                                    products={products}
                                    onSelect={handleSelectList} />
                            );
                        })}
                    </div>
                    <div className={classes.disclaimer}>
                    Prices and stock availability are correct at time of publication and may be subject to change. Please check with individual store for up to date details
                    </div>
                    <div className={classes.footer}>
                        <button onClick={handleAddList}>Make another list</button>
                        <button onClick={handleSaveList}>Save my list</button>
                    </div>
                </div>
            </Drawer>

            <Screen show={isFormOpen} onBack={() => setIsFormOpen(false)}>
                <SendListForm onCancel={() => setIsFormOpen(false)} />
            </Screen>
        </React.Fragment>
    );
};