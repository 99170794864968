import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { ProductData, ProductRecord, ProductVars } from "../types/product";
import { PRODUCTS_PAGINATION } from "../queries/products";
import { saveProducts } from "../store/actions";
import { getProducts, getProductsCount, getStores } from "../store/selectors";
import { Product } from "../store/types";
import { useProductsCount } from "./use-products-count";

// TODO : the list of products does have some issues
export const useProducts = () => {
  const stores = useSelector(getStores);
  const retailerIds = React.useMemo(() => stores?.map(store => store.retailerId).filter((v, i, a) => a.indexOf(v) === i), [stores]);
  const storeIds = React.useMemo(() => stores?.map(store => store.id), [stores]);
  const productCounts = useProductsCount({ retailerIds, storeIds });


  const [tempProducts, setTempProducts] = React.useState<Product[]>([]);
  const [skip, setSkip] = React.useState(0);

  const [fetchProducts, { loading, error, data }] = useLazyQuery<ProductData, ProductVars>(gql(PRODUCTS_PAGINATION));
  const products = useSelector(getProducts);
  const dispatch = useDispatch();

  // This is the place to map the product model if we need to
  const mapProductObject = (product: ProductRecord): Product => {
    let retailer = stores.find(store => store.retailerId === product.retailer.id);
    return {
      id: product.id,
      name: product.name,
      price: product.price,
      description: product.description,
      image: product.image,
      retailer: retailer ? { id: retailer.retailerId, title: retailer.title, logo: retailer.logo, url: retailer.url } : null,
      priceGroups: product.priceGroups,
      categories: product.categories?.map(({ id }) => id),
      featured: product.featured,
      tags: product.tags?.map(({ id }) => id),
      shoppingOptions: product.shoppingOptions,
      campaigns: product.campaigns?.map(({ id }) => id)
    };
  };

  // Fetch the products when we know the retailers and stores
  React.useEffect(() => {
    if (productCounts > 0) {
      // console.log(`fetching products ${0 + skip} - ${100 + skip}`);
      fetchProducts({ variables: { retailerIds, storeIds, skip } });
    }
  }, [productCounts, skip]);

  // Put the products into the redux state
  React.useEffect(() => {
    if (data?.products) {
      // console.log('adding products to temp', data?.products.length);
      var newList = [...tempProducts, ...data.products?.map((product) => mapProductObject(product))]
      setTempProducts(newList);

      // We get 100 at a time, so if there are still more, get the next batch
      if ((skip + 100) < productCounts) { // 
        setSkip(skip + 100)
      }
      // Otherwise, we are done, so save the products to the store
      else {
        // console.log('saving product data');
        dispatch(saveProducts(newList));
      }

    }
  }, [data]);

  return products;
};
