@use "../../styles/imports/utils";
@use "../../styles/imports/variables" as vars;

.header {
    height: 109px;
    background: vars.$yellow;
    position: relative;

    .image {
        position: absolute;
        left: 6%;
        top: 0;
        height: 100%;
        display: block;
        object-fit: contain;
    }

    .title {
        position: absolute;
        left: 40%;
        top: 50%;
        transform: translateY(-50%);
        color: black;
        text-align: center;
        margin: 0;
        font-size: 22px;
        line-height: 1.5;
        text-transform: uppercase;
        max-width: 200px;
    }
}

@media screen and (min-width: 455px) {
    .header {
        .image {
            left: 12%;
        }
    }
}
