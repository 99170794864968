import React from 'react';
import { ChatBlockBackAction, ChatBlockCategoriesElement, ChatBlockElement, ChatBlockInputElement, ChatBlockMoveAction, ChatBlockOptionsElement, ChatBlockResultsElement, ChatBlockSliderElement, ElementType } from '../../store/types';
import { CategoriesElement } from '../categories-element/categories-element';
import { InputElement } from '../input-element/input-element';
import { OptionsElement } from '../options-element/options-element';
import { Results } from '../results/results';
import { SliderElement } from '../slider-element/slider-element';

type ElementProps = {
    element: ChatBlockElement;
    answerId: string;
    isActive: boolean;
    onNext: (action: string, value: string) => void;
    onBack: (newList?: boolean) => void;
    onSave: () => void;
};

export const Element: React.FC<ElementProps> = ({ element, answerId, isActive, onNext, onBack, onSave }) => {
    return (
        <React.Fragment>
            {element.type === ElementType.MoveAction &&
                <ActionElement onRender={() => onNext((element as ChatBlockMoveAction).moveTo?.id, null)} />
            }
            {element.type === ElementType.BackAction &&
                <ActionElement onRender={() => onBack((element as ChatBlockBackAction).newList)} />
            }
            {element.type === ElementType.SaveAction &&
                <ActionElement onRender={onSave} />
            }
            {element.type === ElementType.Results &&
                <Results {...(element as ChatBlockResultsElement)} isActive={isActive} onReachedEnd={() => onNext(null, null)} />
            }
            {element.type === ElementType.Input &&
                <InputElement answer={answerId} inputId={(element as ChatBlockInputElement).input?.id} onSubmit={(value) => onNext(null, value)} />
            }
            {element.type === ElementType.Option &&
                <OptionsElement options={(element as ChatBlockOptionsElement).options} answerId={answerId} onSelect={onNext} onBack={onBack} onSave={onSave} />
            }
            {element.type === ElementType.Category &&
                <CategoriesElement heading={(element as ChatBlockCategoriesElement).text} answerId={answerId} onSelect={onNext} />
            }
             {element.type === ElementType.Slider &&
                <SliderElement {...(element as ChatBlockSliderElement)} answerId={answerId} onSelect={(value) => onNext(null, value)} />
            }
        </React.Fragment>
    );
};

const ActionElement: React.FC<{ onRender: () => void }> = ({ onRender }) => {
    React.useEffect(() => {
        onRender();
    }, []);

    return null;
};