import { Img } from "../types/img";

export interface ContentState {
  products: Product[];
  categories: Category[];
  stores: Store[];
  chatData: {
    chatBlocks: ChatBlockLookup; // Lookup of chatblocks by id
    chatFlow: string[]; // The flat chat flow in order - chat block ids
    chatSetting: ChatSettings,// The general settings for the chat
    inputFields: InputField[];
    priceGroups: PriceGroup[]; // needed for slider and showing other results
  };
  shoppingCentre: ShoppingCentre;
  productsCount: number;
  campaignId: string;
}

export interface ShoppingCentre {
  id: string;
  title: string;
  key: string;
  emailSender: string;
  emailReplyTo: string;
  edmBanner?: Img;
  subscribe: {
    id: number;
    dbControl: string;
  }
  websiteLink?: string;
  storesLink?: string;
  openingHoursLink?: string;
  blogLink?: string;
  facebook?: string;
  instragram?: string;
  address?: string;
  tripadvisor?: string;
  logoLink?: string;
}

export interface InputField {
  id: string;
  title: string;
  inputType: string;
}

export interface PriceGroup{
  id: string;
  key: string;
  position: number;
}

export interface ChatSettings {
  avatar: Img; //default avatar
  header: Img;
  chatFlowType?: ChatFlowType;
  delay?: number;
  icon: Img;
  headerText: string;
  edmBanner: Img;
}

export enum ChatFlowType {
  Single = "single-flow",
  CategoryBased = "category-based",
}

export type ChatBlockLookup = { [key: string]: ChatBlock };

export enum MessageTextType {
  Span = "span",
  InlineItem = "inlineItem",
}

export type MessageSpan = {
  type: MessageTextType;
  value: string;
};

export type MessageInlineItem = {
  type: MessageTextType;
  item: string;
};

export type MessageText = MessageSpan | MessageInlineItem;

export interface ChatBlockMessage {
  text: MessageText[]
}


export enum ElementType {
  MoveAction = "action_move_to",
  SaveAction = "action_save_list",
  BackAction = "action_back",
  Option = "options_element",
  Category = "categories_element",
  Input = "input_element",
  Results = "results_element",
  Slider = "slider_element"
}

export type ChatBlockInputElement = {
  type: ElementType;
  input: { id: string };
};

export type ChatBlockResultsElement = {
  type: ElementType;
  otherCategories?: boolean;
  skip?: number;
  take?: number;
  filterByTags?: boolean;
  noResultsMessage: string;
};

export type ChatBlockMoveAction = {
  type: ElementType;
  moveTo: { id: string }
};

export type ChatBlockSaveAction = {
  type: ElementType;
};

export type ChatBlockBackAction = {
  type: ElementType;
  newList: boolean;
};

export type ChatBlockOptionsElement = {
  type: ElementType;
  options: Option[];
};

export type Option = {
  id: string;
  text: string;
  tags: { id: string }[];
  action: ChatBlockSaveAction | ChatBlockMoveAction | ChatBlockBackAction;
};

export type ChatBlockCategoriesElement = {
  type: ElementType;
  text: string;
};

export type ChatBlockSliderElement = {
  type: ElementType;
  heading: string;
  levels: SliderLevel[];
}

export type SliderLevel = {
  id: string;
  text: string
  priceGroups: { id: string }[];
  tags: { id: string }[]
}

export type ChatBlockElement = ChatBlockInputElement | ChatBlockResultsElement | ChatBlockOptionsElement | ChatBlockCategoriesElement | ChatBlockSliderElement | ChatBlockMoveAction | ChatBlockBackAction | ChatBlockSaveAction;

export interface ChatBlock {
  messages: ChatBlockMessage[];
  element: ChatBlockElement;
}

export interface AppState {
  giftLists: GiftList[];
  activeListId: string;
  userDetails: UserDetails;
}

export interface UserDetails {
  firstName: string;
  lastName: string;
  email: string;
  mobile?: string;
  postcode?: string;
}

export interface GiftList {
  id: string;
  name: string;
  category: string; // category id
  tags: string[]; // tag ids
  products: string[]; // ids only
  priceGroups: string[]; //ids only
  inputs: { [inputId: string]: string };
  chatHistory: { chatBlockId: string, answerId?: string }[];
}

export interface Category {
  id: string;
  title: string;
  value: string;
  personaName: string;
  personaAvatar: Img;
  selectAll: boolean;
  chatEntry: {
    id: string;
  };
}

export interface Product {
  id: string;
  name: string;
  price: number;
  description: string;
  image: Img;
  retailer: {
    id: string;
    title: string;
    logo: Img;
    url: string;
  };
  shoppingOptions: {
    id: string;
    title: string;
    value: string;
  }[];
  tags: string[];
  categories: string[];
  priceGroups: {
    id: string;
    title: string;
    key: string;
    position: number;
  }[];
  featured: boolean;
  campaigns: string[]
}

export interface Store {
  id: string;
  title: string;
  logo: Img;
  retailerId: string;
  url: string;
}

export const SET_ACTIVE_LIST = "SET_ACTIVE_LIST";
export const SAVE_CHAT_HISTORY = "SAVE_CHAT_HISTORY";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const SAVE_NAME = "SAVE_NAME";
export const SAVE_INPUT = "SAVE_INPUT";
export const SAVE_CATEGORY = "SAVE_CATEGORY";
export const ADD_TAGS = "ADD_TAGS";
export const REMOVE_TAGS = "REMOVE_TAGS";
export const SAVE_PRICE_GROUPS = "SAVE_PRICE_GROUPS";
export const SAVE_USER_DETAILS = "SAVE_USER_DETAILS";
export const ADD_NEW_LIST = "ADD_NEW_LIST";

export const SAVE_PRODUCTS = "SAVE_PRODUCTS";
export const SAVE_PRODUCTS_COUNT = "SAVE_PRODUCTS_COUNT";
export const SAVE_CAMPAIGN = "SAVE_CAMPAIGN";
export const SAVE_CATEGORIES = "SAVE_CATEGORIES";
export const SAVE_STORES = "SAVE_STORES";
export const SAVE_CHAT_DATA = "SAVE_CHAT_DATA";
export const SAVE_CHAT_BLOCKS = "SAVE_CHAT_BLOCKS";
export const SAVE_SHOPPING_CENTRE = "SAVE_SHOPPING_CENTRE";

interface SetActiveListAction {
  type: typeof SET_ACTIVE_LIST;
  giftListId: string;
}

interface SaveChatHistoryAction {
  type: typeof SAVE_CHAT_HISTORY;
  history: {
    chatBlockId: string;
    answerId?: string;
  }[];
  giftListId: string;
}

interface AddProductAction {
  type: typeof ADD_PRODUCT;
  productId: string;
  giftListId: string;
}

interface RemoveProductAction {
  type: typeof REMOVE_PRODUCT;
  productId: string;
  giftListId: string;
}

interface SaveNameAction {
  type: typeof SAVE_NAME;
  giftListId: string;
  name: string;
}

interface SaveInputAction {
  type: typeof SAVE_INPUT;
  giftListId: string;
  value: string;
  inputId: string;
}

interface SaveCategoryAction {
  type: typeof SAVE_CATEGORY;
  giftListId: string;
  category: string;
}

interface SavePriceGroupsAction {
  type: typeof SAVE_PRICE_GROUPS;
  giftListId: string;
  priceGroups: string[];
}

interface AddTagsAction {
  type: typeof ADD_TAGS;
  giftListId: string;
  tags: string[];
}

interface RemoveTagsAction {
  type: typeof REMOVE_TAGS;
  giftListId: string;
  tags: string[];
}

interface SaveUserDetailsAction {
  type: typeof SAVE_USER_DETAILS;
  userDetails: UserDetails;
}

interface SaveProductsAction {
  type: typeof SAVE_PRODUCTS;
  products: Product[];
}

interface SaveProductsCountAction {
  type: typeof SAVE_PRODUCTS_COUNT;
  count: number;
}

interface SaveCampaignAction {
  type: typeof SAVE_CAMPAIGN;
  campaignId: string;
}

interface SaveCategoriesAction {
  type: typeof SAVE_CATEGORIES;
  categories: Category[];
}

interface SaveStoresAction {
  type: typeof SAVE_STORES;
  stores: Store[];
}

interface SaveChatDataAction {
  type: typeof SAVE_CHAT_DATA;
  chatFlow: string[];
  inputFields: InputField[];
  priceGroups: PriceGroup[];
  chatSetting: ChatSettings;
}
interface SaveChatBlocksAction {
  type: typeof SAVE_CHAT_BLOCKS;
  chatBlocks: { [key: string]: ChatBlock };
}


interface AddNewListAction {
  type: typeof ADD_NEW_LIST;
}

interface SaveShoppingCentreAction {
  type: typeof SAVE_SHOPPING_CENTRE;
  shoppingCentre: ShoppingCentre;
}

export type ContentActionTypes =
  | SaveProductsAction
  | SaveCategoriesAction
  | SaveStoresAction
  | SaveChatDataAction
  | SaveChatBlocksAction
  | SaveShoppingCentreAction
  | SaveProductsCountAction
  | SaveCampaignAction;

export type AppActionTypes =
  | SetActiveListAction
  | SaveChatHistoryAction
  | AddProductAction
  | RemoveProductAction
  | SaveNameAction
  | SaveInputAction
  | SaveCategoryAction
  | SavePriceGroupsAction
  | AddTagsAction
  | RemoveTagsAction
  | SaveUserDetailsAction
  | AddNewListAction;
