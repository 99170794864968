import React from 'react';
import { useProducts } from '../hooks/use-products';
import { useStores } from '../hooks/use-stores';
import { useCategories } from '../hooks/use-categories';
import { useShoppingCentre } from '../hooks/use-shopping-centre';

export const AppData: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    const getShoppingCentreKey = () => {
        let hostName = window.location.hostname;
        // If its localhost or staging, the key can be passed via query string to test
        if (hostName == "localhost" 
        || hostName.match(/(https?:\/\/?)?(www.)?(.+)(.staging)?.mirvac.com(\/.*)?/)
         || hostName.match(/(https?:\/\/?)?(www.)?gift-guide.havasww.com.au(\/.*)?/)) {
            const urlParams = new URLSearchParams(window.location.search);
            var key = urlParams.get('key') ?? "broadwaysydney";
            hostName = `${key}.com.au`
        }

        const matches = hostName.match(/(www.)?(.+).com.au(\/.*)?/);
        return matches && matches[2];

    }

    const shoppingCenterKey = React.useMemo(() => getShoppingCentreKey(), []);
    const shoppingCentre = useShoppingCentre(shoppingCenterKey);
    useStores(shoppingCentre?.id);
    useCategories();
    const products = useProducts();

    if (!products?.length) return <div className="loading">Loading...</div>;


    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};