import React from 'react';
import { utils } from '../../services/utils';
import { Img } from '../../types/img';
import * as classes from './avatar-heading.module.scss';

export const AvatarHeading: React.FC<{ img: Img, heading: string; text?: string }> = ({ img, heading, text }) => {
    const croppedImg = React.useMemo(() => utils.addQueryStringParams(img?.url, [
        { name: 'h', value: '100' },
        { name: 'w', value: '100' },
        { name: 'fit', value: 'crop' },
        { name: 'crop', value: 'focalpoint' },
        { name: 'fp-x', value: `${img?.focalPoint?.x || 0.5}` },
        { name: 'fp-y', value: `${img?.focalPoint?.y || 0.5}` },
    ]), [img]);
    
    return (
        <div className={classes['avatar-heading']}>
            <div className={classes.avatar} style={{ backgroundImage: `url(${croppedImg})` }} />
            <h2 className={classes.heading}>{heading}</h2>
            <small>{text}</small>
        </div>
    );
};