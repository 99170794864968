import { createStore } from "redux";
import { stateLoader } from "../services/state-loader";
import { rootReducer, RootState } from "./reducers";
import { v4 as uuidv4 } from "uuid";

let appState = stateLoader.loadAppState();

// Create a default list
if (!appState?.giftLists?.length) {
  appState.giftLists = [
    {
      id: uuidv4(),
      name: "",
      category: "",
      tags: [],
      products: [],
      chatHistory: [],
      inputs:{}
    },
  ];
}

// Set the active list
if (!appState.activeListId) {
  appState.activeListId = appState.giftLists[0].id;
}

let initialState: RootState = {
  app: appState,
  content: null
};

export const store = createStore(rootReducer, initialState);

store.subscribe(() => {
  let newState = store.getState();
  stateLoader.saveAppState(newState.app);
});
