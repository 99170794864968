import React from 'react';

export const useIntersection = (ref, options = null, setOnce = true) => {
    const [intersection, setIntersection] = React.useState<{ isIntersecting: boolean }>({ isIntersecting: false });

    React.useEffect(() => {
        const node = ref?.current;
        const observer = new window.IntersectionObserver(([entry]) => {
            setIntersection(entry);

            if (entry.isIntersecting && setOnce) {
                observer.unobserve(entry.target);
            }

        }, { root: document.getElementById('gift-guide-popup'), ...options });

        if (node) {
            observer.observe(node);
        }

        return () => {
            observer.disconnect();
        };
    }, [ref]);

    return intersection;
};