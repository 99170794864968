@use "../../styles/imports/variables.scss";
@use "../../styles/imports/utils.scss";

.heart-button {
     composes: icon-button from "../icon-button/icon-button.module.scss";

    background-color: white;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
    color: variables.$red;
    height: 32px;
    width: 32px;

    &:hover,
    &:focus {
        color: variables.$red;
    }

    &.active {
        svg {
            fill: variables.$lighter-red;
        }
    }
}
