export const CHAT_DATA = `
query getChatData($campaignId :ItemId) {
  chatSetting(filter: {campaign: {eq: $campaignId}, active: {eq: true}}){
    avatar{
      url
      alt
      focalPoint {
        x
        y
      }
    }
    header{
      url
      alt
      focalPoint {
        x
        y
      }
    }
    icon {
      url
      alt
      }
    edmBanner {
      url
      alt
      }
    active
    headerText
    chatflowType
    delay
    chatflow:chatEntry {
      id
      position
      children {
        position
        id
        children {
          position
          id
          children {
            position
            id
          }
        }
      }
    }
  }
  inputFields: allInputs(first: 100) {
    id
    title
    inputType
  }
  priceGroups: allPriceGroups(first: 100) {
    id
    key
    position
  }
}

`;


