import React from 'react';
import * as classes from './options-element.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveListId } from '../../store/selectors';
import { addTags, removeTags } from '../../store/actions';
import { ElementType, Option } from '../../store/types';
import { utils } from '../../services/utils';

type OptionsElementProps = {
    options: Option[],
    answerId: string,
    onSelect: (action: string, value: string) => void;
    onBack: (newList?: boolean) => void;
    onSave: () => void;
};

export const OptionsElement: React.FC<OptionsElementProps> = ({ options, answerId, onSelect, onBack, onSave }) => {
    const giftListId = useSelector(getActiveListId);
    const dispatch = useDispatch();

    const handleSelect = (action, optionId: string, text: string, tags?: string[]) => {
        utils.createGtmEvent('option-selected', { value: text });

        // If changing the selected option, remove the old tags first.
        if (answerId !== optionId) {
            let prevOption = answerId && options.find(option => option.id === answerId);
            if (prevOption?.tags?.length) dispatch(removeTags(giftListId, prevOption.tags.map(tag => tag.id)));

            // Now add the new tags and update the selected option
            if (tags) dispatch(addTags(giftListId, tags));
        }

        if (!action || action?.type === ElementType.MoveAction) {
            onSelect(action?.moveTo?.id, optionId);
        }

        if (action?.type === ElementType.BackAction) {
            onBack(action?.newList);
        }

        if (action?.type === ElementType.SaveAction) {
            onSave();
        }
    };

    return (
        <div className={classes['options-element'] + (options.length > 2 ? ` ${classes['options-wrap']}` : '')}>
            {options.map(({ id, text, tags, action }) => {
                return (
                    <button key={id}
                        className={classes.option + (id === answerId ? ` ${classes.active}` : '')}
                        onClick={() => handleSelect(action?.[0], id, text, tags.map(tag => tag.id))}>
                        {text}
                    </button>
                );
            })}
        </div>
    );
};