import React from 'react';
import { Product } from '../../store/types';
import { IconButton } from '../icon-button/icon-button';
import Chevron from '../../assets/svgs/chevron-down.svg';
import Trash from '../../assets/svgs/trash.svg';
import Edit from '../../assets/svgs/edit.svg';
import Plus from '../../assets/svgs/plus.svg';
import * as classes from './gift-list.module.scss';
import { useDispatch } from 'react-redux';
import { removeProduct, saveChatHistory } from '../../store/actions';
import { CSSTransition } from 'react-transition-group';
import { Logo } from '../logo/logo';

type GiftListProps = {
    id: string;
    name: string;
    category: {title: string};
    products: Product[];
    onSelect: (listId: string) => void;
};

export const GiftList: React.FC<GiftListProps> = ({ id, name, category, products, onSelect }) => {
    const [isExpanded, setIsExpanded] = React.useState<boolean>(true);
    const dispatch = useDispatch();

    const handleDelete = (productId: string) => {
        dispatch(removeProduct(id, productId));
    };

    const handleBack = () => {
        // clear the chat history to go back to the start
        dispatch(saveChatHistory(id, []));
        onSelect(id); // Activate this list
    };

    return (
        <div className={classes['gift-list'] + (isExpanded ? ` ${classes.expanded}` : '')}>
            <div className={classes.header}>
                <div className={classes.heading}>

                    <h3>Gifts {name ? ` for ${name}` : ''}</h3>
                    <IconButton onClick={() => setIsExpanded(!isExpanded)}><Chevron className={classes.chevron} /></IconButton>
                </div>

                {/* <button className={classes.edit} onClick={handleBack}>
                    <small>{category?.title}</small>
                    <Edit className={classes["edit-icon"]} />
                </button> */}
            </div>
            <CSSTransition
                mountOnEnter
                unmountOnExit
                in={isExpanded}
                timeout={300}
                classNames={{
                    enter: classes['content-enter'],
                    enterActive: classes['content-enter-active'],
                    exit: classes['content-exit'],
                    exitActive: classes['content-exit-active'],
                }}>
                <div>
                    <ul className={classes.products}>
                        {products.map(({ id, name, price, description, image, retailer }) => {
                            return (
                                <li key={`product-${id}`} className={classes.product}>
                                    <div className={classes.image} style={{ backgroundImage: `url(${image?.url})` }}>
                                        {!!retailer?.logo && <Logo className={classes.logo} logo={retailer.logo} />}
                                    </div>
                                    <div className={classes.details}>
                                        <h3>{name}<br />{price}</h3>
                                        <small>{description}</small>
                                    </div>
                                    <IconButton className={classes.delete} onClick={() => handleDelete(id)}><Trash /></IconButton>
                                </li>
                            );
                        })}
                        <li>
                            <div className={classes['add-button']}>
                                <IconButton className={classes['plus-icon']} onClick={() => onSelect(id)}><Plus /></IconButton>
                                Add more gifts {name ? ` for ${name}` : ''}
                            </div>
                        </li>
                    </ul>
                </div>
            </CSSTransition>
        </div>
    );
};