import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveInput, saveName } from '../../store/actions';
import { getActiveListId, getInputByType } from '../../store/selectors';
import { IconButton } from '../icon-button/icon-button';
import * as classes from './input-element.module.scss';
import Arrow from '../../assets/svgs/arrow.svg';
import { useChatData } from '../../hooks/use-chat-data';

export const InputElement: React.FC<{ answer: string, inputId: string, onSubmit: (value: string) => void }> = ({ answer, inputId, onSubmit }) => {
    const [value, setValue] = React.useState<string>(answer || '');
    const giftListId = useSelector(getActiveListId);
    const firstNameInput = useSelector(getInputByType("firstname"));

    const dispatch = useDispatch();


    const handleSubmit = (event) => {
        if (firstNameInput && firstNameInput?.id == inputId)
            dispatch(saveName(giftListId, value))
        dispatch(saveInput(giftListId, value, inputId));
        onSubmit(value);
        event.preventDefault();
    };

    return (
        <form className={classes['input-element']} onSubmit={handleSubmit}>
            <input type="text" value={value} onChange={(event) => setValue(event.target.value)} />
            <IconButton className={classes.submit} type="submit"><Arrow /></IconButton>
        </form>
    );
};