@use "../../styles/imports/utils";
@use "../../styles/imports/variables" as vars;

.options-element {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    max-width: 300px;

    .option {
        padding: 12px;
        border: none;
        border-radius: 10px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.07);
        color: black;
        background-color: white;
        margin-left: 12px;

        &.active {
            background-color: vars.$hero-colour-2;
        }
    }

    &.options-wrap {
        flex-wrap: wrap;
        .option {
            margin-bottom: 12px;
        }
    }
}
