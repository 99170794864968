@use "../../styles/imports/variables" as vars;
@use "../../styles/imports/utils";

.icon-button {
    background-color: vars.$light-pink;
    color: vars.$brown;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: color 0.4s, background-color 0.4s;

    &:hover,
    &:focus {
        color: darken(vars.$brown, 2%);
        background-color: darken(vars.$light-pink, 2%);
    }
}
