import React from 'react';
import ReactDOM from 'react-dom';
import { PopUp } from './components/pop-up/pop-up';
import './styles/style.scss'
import { onError } from '@apollo/client/link/error';
import {
    ApolloClient,
    ApolloProvider,
    ApolloLink,
    HttpLink
} from "@apollo/client";
import { Chat } from './components/chat/chat';
import { cache } from './cache';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { Dashboard } from './components/dashboard/dashboard';
import { AppData } from './controllers/app-data';
const client = new ApolloClient({
    link: ApolloLink.from([
        onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors)
                graphQLErrors.forEach(({ message, locations, path }) =>
                    console.log(
                        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                    ),
                );
            if (networkError) console.log(`[Network error]: ${networkError}`);
        }),
        new HttpLink({
            uri: process.env.GRAPHQL_ENDPOINT,
            credentials: 'same-origin',
            headers: {
                authorization: `Bearer ${process.env.GRAPHQL_API_KEY}`
            }
        })
    ]),
    cache: cache
});

const App = () => {
    return (
        <ApolloProvider client={client}>
            <Provider store={store}>
                <PopUp>
                    <AppData>
                        <Chat />
                        <Dashboard />
                    </AppData>
                </PopUp>
            </Provider>
        </ApolloProvider>
    );
}

window.onload = () => {
    const root = document.getElementById(process.env.GIFT_FINDER_ELEMENT_ID);
    ReactDOM.render(<App />, root);
    const trigger = document.getElementById("gift-guide-trigger");
    trigger.style.display = "block";
}