import React from 'react';
import { Img } from '../../types/img';
import * as classes from './logo.module.scss';

export const Logo: React.FC<{ logo: Img, className?: string }> = ({ logo, className }) => {
    if (!logo?.url) return null;

    return (
        <div className={classes.logo + (className ? ` ${className}` : '')} style={{ backgroundImage: `url(${logo.url})` }} />
    );
};