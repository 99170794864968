export const CHAT_BLOCKS = `
query getChatBlocks($blockIds: [ItemId]) {
  chatBlocks: allChatBlocks(first: 100, filter: {id: {in: $blockIds}}) {
    id
    position
    title
    messages {
      ...textBlockFragment
    }
    elements {
      ...actionBackFragment
      ...actionSaveListFragment
      ...actionMoveToFragment
      ...resultsElementFragment
      ...inputElementFragment
      ...categoriesElementFragment
      ...OptionsElementFragment
      ...SliderElementFragment
    }
  }
  chatBlocksSecond: allChatBlocks(skip: 100, first: 100, filter: {id: {in: $blockIds}}) {
    id
    position
    title
    messages {
      ...textBlockFragment
    }
    elements {
      ...actionBackFragment
      ...actionSaveListFragment
      ...actionMoveToFragment
      ...resultsElementFragment
      ...inputElementFragment
      ...categoriesElementFragment
      ...OptionsElementFragment
      ...SliderElementFragment
    }
  }
}

fragment actionMoveToFragment on ActionMoveToRecord {
  type: _modelApiKey
  moveTo {
    id
  }
}

fragment actionBackFragment on ActionBackRecord {
  type: _modelApiKey
  newList
}

fragment actionSaveListFragment on ActionSaveListRecord {
  type: _modelApiKey
}

fragment inputElementFragment on InputElementRecord {
  type: _modelApiKey
  input {
    id
  }
}

fragment textBlockFragment on TextBlockRecord {
  _modelApiKey
  text {
    value
  }
}

fragment resultsElementFragment on ResultsElementRecord {
  type: _modelApiKey
  otherCategories
  skip
  take
  filterByTags
  noResultsMessage
}

fragment optionElementFragment on OptionElementRecord {
  _modelApiKey
  id
  tags {
    id
  }
  text
  action {
    ... on ActionMoveToRecord {
      type: _modelApiKey
      moveTo {
        id
      }
    }
    ... on ActionBackRecord {
      type: _modelApiKey
      newList
    }
    ... on ActionSaveListRecord {
      type: _modelApiKey
    }
  }
}

fragment categoriesElementFragment on CategoriesElementRecord {
  type: _modelApiKey
  text
}

fragment OptionsElementFragment on OptionsElementRecord {
  type: _modelApiKey
  options {
    ...optionElementFragment
  }
}
fragment SliderElementFragment on SliderElementRecord {
  type: _modelApiKey
  heading:text
  levels {
    id
    text
    priceGroups {
      id
    }
    tags {
      id
    }
  }
}



`;



