@use "../../styles/imports/utils";
@use "../../styles/imports/variables" as vars;

.dashboard-button {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 56px;
    border-radius: 20px;
    box-shadow: 0px -5px 15px 0px rgba(105, 81, 81, 0.15);
    border: none;
    padding: 0;
    background: white;
    z-index: 10;

    .heart {
        color: vars.$red;
        fill: vars.$red;
        margin-right: 8px;
    }
}

.dashboard {
    background: white;
    height: 100%;
    padding: 0 vars.$container-padding;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .heart {
        color: vars.$red;
        fill: vars.$red;
        margin-right: 8px;
    }

    .header {
        display: flex;
        align-items: center;
        padding: 14px 0;
        border-bottom: 1px solid vars.$grey;

        h2 {
            margin-right: auto;
            margin-bottom: 0;
        }
    }

    .content {
        flex-grow: 1;
    }

    .disclaimer {
        font-size: 14px;
        margin-top: 20px;
    }

    .footer {
        display: flex;
        padding: 20px 0;
        button {
            background-color: vars.$background-colour;
            color: vars.$brown;
            border-radius: 6px;
            border: none;
            padding: 12px 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: color 0.4s,background-color 0.4s;
            width: 50%;

            &:hover,
            &:focus {
                color: darken(vars.$brown, 2%);
                background-color: darken(vars.$background-colour, 2%);
            }

            &:first-child{
                margin-right: 10px;
            }
        }
    }
}
