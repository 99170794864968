import React from 'react';
import * as classes from './slider-element.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveList } from '../../store/selectors';
import { Img } from '../../types/img';
import { utils } from '../../services/utils';
import { SliderLevel } from '../../store/types';
import { addTags, removeTags, savePriceGroups } from '../../store/actions';
import Arrow from '../../assets/svgs/up-arrow.svg';

export const SliderElement: React.FC<{ heading: string, levels: SliderLevel[], answerId: string, onSelect: (value: string) => void }> = ({ heading, levels, answerId, onSelect }) => {
    const list = useSelector(getActiveList);
    const dispatch = useDispatch();

    const getLevelBasedOnTags = () => {
        if (list?.tags?.length) {
            let levelsByMostTags = levels.map(level => {
                let matchingTagsCount = level.tags.filter(tag => list.tags.includes(tag.id)).length;
                return { level, matchingTagsCount };
            }).sort((item1, item2) => item2.matchingTagsCount - item1.matchingTagsCount);
            return levelsByMostTags[0].level;
        }

        return null;
    };

    // pick the slide level with the most matching tag as the default pick
    const selectedLevel = (answerId && levels.find(level => level.id === answerId)) || getLevelBasedOnTags() || levels[0];

    const handleSelect = (levelId: string, title: string, priceGroups: string[], tags: string[], addEvent: boolean) => {
        // add the related price groups in the list in the active list
        // filter result base on this in result - if has values

        if(addEvent) utils.createGtmEvent('level-selected', { value: title });

        // save the price groups attached to the selected level. "get products" should be filtered based on these
        dispatch(savePriceGroups(list?.id, priceGroups));

        // save the tags attached to the selected level. "get products" should be filtered based on these
        // If changing the selected level, remove the old tags and price first (since tags are cumulative across questions)
        if (answerId !== levelId) {
            let prevLevel = answerId && levels.find(level => level.id === answerId);
            if (prevLevel?.tags?.length) dispatch(removeTags(list?.id, prevLevel.tags.map(tag => tag.id)));

            // Now add the new tags
            if (tags) dispatch(addTags(list?.id, tags));
        }

        // update the selected level
        onSelect(levelId);
    };

    const setSelectedLevel = (level: SliderLevel, addEvent: boolean) => {
        handleSelect(level.id, level.text, level.priceGroups.map(priceGroup => priceGroup.id), level.tags.map(tag => tag.id), addEvent);
    };

    React.useEffect(() => {
        if (selectedLevel.id !== answerId) {
            setSelectedLevel(selectedLevel, false);
        }
    }, []);

    const [isDragging, setIsDragging] = React.useState<boolean>();
    const indicatorRef = React.useRef<HTMLDivElement>();
    const trackRef = React.useRef<HTMLDivElement>();
    const [x, setX] = React.useState<number>(0);
    const [diff, setDiff] = React.useState<number>(0);
    let portionOfTrack = (levels.indexOf(selectedLevel) * 2 + 1) / (levels.length * 2);
    let indicatorX = Math.max(Math.min(portionOfTrack * trackRef?.current?.clientWidth + diff, trackRef?.current?.clientWidth), 0);

    const onMouseDown = (e) => {
        dragStart(e);
        e.stopPropagation();
        e.preventDefault();
    };

    const onTouchStart = (e) => {
        dragStart(e);
    };

    const dragStart = (e) => {
        var x0 = unify(e).clientX;
        setIsDragging(true);
        setX(x0);
    };

    React.useEffect(() => {
        const drag = (e) => {
            if (!isDragging) return
            var x0 = unify(e).clientX;
            setDiff(x0 - x);
        };

        const onMouseMove = (e) => {
            drag(e);
            e.stopPropagation();
            e.preventDefault();
        };

        const onTouchMove = (e) => {
            drag(e);
        };

        const dragEnd = (e) => {
            if (!isDragging) return

            var levelWidth = trackRef?.current?.clientWidth / levels.length;
            var levelsMoved = ~~(diff / levelWidth); // answer without remainder
            var remainder = (diff % levelWidth) / levelWidth;
            if (Math.abs(remainder) > 0.5) levelsMoved = diff < 0 ? levelsMoved - 1 : levelsMoved + 1;
            if (levelsMoved) {
                var newLevelIndex = Math.max(Math.min(levels.indexOf(selectedLevel) + levelsMoved, levels.length - 1), 0);
                setSelectedLevel(levels[newLevelIndex], true);
            }

            setIsDragging(false);
            setX(0);
            setDiff(0);
        };

        const onMouseUp = (e) => {
            dragEnd(e);
            e.stopPropagation();
            e.preventDefault();
        };

        const onTouchEnd = (e) => {
            dragEnd(e);
        };

        if (isDragging) {
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);

            document.addEventListener('touchmove', onTouchMove);
            document.addEventListener('touchend', onTouchEnd);
        }

        return () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);

            document.removeEventListener('touchmove', onTouchMove);
            document.removeEventListener('touchend', onTouchEnd);
        };
    }, [isDragging, diff]);


    function unify(event) {
        return event.touches ? event.touches[0] : event;
    }

    return (
        <div className={classes['slider-element']}>
            {!!heading && <h2 className={classes.title}>{heading}</h2>}
            <div className={classes.slider}>
                <span>1</span>
                <div ref={trackRef} className={classes.track}
                    style={{ backgroundPositionX: `${indicatorX}px` }}>
                    {levels.map(({ id, priceGroups, tags, text }) => {
                        return (
                            <button
                                key={id}
                                className={classes.level}
                                onClick={() => handleSelect(id, text, priceGroups.map(priceGroup => priceGroup.id), tags.map(tag => tag.id), true)} />
                        );
                    })}
                    <div ref={indicatorRef} className={classes.indicator}
                        onMouseDown={onMouseDown} onTouchStart={onTouchStart}
                        style={{ transform: `translateX(${indicatorX - indicatorRef?.current?.clientWidth / 2}px)` }}>
                        <Arrow />
                    </div>
                </div>
                <span>{levels.length}</span>
            </div>
            {!!selectedLevel && <div className={classes.detail}>
                <h3>Level {levels.indexOf(selectedLevel) + 1}</h3>
                <p>{selectedLevel.text}</p>
            </div>}
        </div>
    );
};