import React from 'react';
import * as classes from './modal.module.scss';
import { CSSTransition } from 'react-transition-group';
import { Portal } from '../portal/portal';
import { IconButton } from '../icon-button/icon-button';
import XIcon from '../../assets/svgs/x.svg';

export const Modal: React.FC<{ show: boolean, onClose: () => void, children: React.ReactNode }> = ({ show, onClose, children }) => {
    return (
        <CSSTransition
            in={show}
            timeout={300}
            classNames={{
                enter: classes['modal-enter'],
                enterActive: classes['modal-enter-active'],
                exit: classes['modal-exit'],
                exitActive: classes['modal-exit-active'],
            }}
            unmountOnExit>
            <Portal portalId="gift-guide-modal-portal">
                <div className={classes.modal}>
                    <div className={classes['modal-inner']}>
                        <IconButton className={classes.close} onClick={onClose}><XIcon /></IconButton>
                        {children}
                    </div>
                </div>
            </Portal>
        </CSSTransition>
    );
};