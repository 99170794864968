@use "../../styles/imports/variables.scss" as vars;
@use "../../styles/imports/utils.scss";

.gift-list {
    &.expanded {
        .chevron {
            transform: scale(-1);
        }
    }

    .header {
        padding: 20px 0;
        border-bottom: 1px solid vars.$grey;
    }

    .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
            margin: 0;
        }
    }

    .edit {
        background: none;
        border: none;
        padding: 0;
    }

    .edit-icon {
        margin-left: 4px;
    }

    .products {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            border-bottom: 1px solid vars.$grey;
            padding: 20px 0;
        }
    }

    .product {
        display: flex;

        .image {
            width: 109px;
            height: 109px;
            margin-right: 14px;
            border-radius: 10px;
            @include utils.background-image();
            background-color: vars.$light-pink; // Just a backup for no image
            flex-shrink: 0;
            position: relative;
        }

        .logo {
            position: absolute;
            left: 5px;
            top: -6px;
        }

        .details {
            margin-right: 14px;
            flex-grow: 1;
        }

        .delete {
            flex-shrink: 0;
        }
    }

    .add-button {
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .plus-icon {
            margin-right: 8px;
            &:after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
                content: "";
            }
        }
    }

    .content-enter {
        opacity: 0;
        max-height: 0;
    }
    .content-enter-active {
        opacity: 1;
        max-height: 100vh;
        transition: max-height 300ms, opacity 300ms;
    }
    .content-exit {
        opacity: 1;
        max-height: 100vh;
    }
    .content-exit-active {
        opacity: 0;
        max-height: 0;
        transition: max-height 300ms, opacity 300ms;
    }
}
