@use "../../styles/imports/utils";

.logo {
    border: none;
    border-radius: 50%;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
    height: 36px;
    width: 36px;
    @include utils.background-image();
}
