@use "../../styles/imports/utils";

.chatBlock {
    .messages {
        display: flex;
    }

    .messageSender {
        flex-shrink: 0;
    }

    .message-items {
        flex-grow: 1;
        margin-left: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        > * {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    .element {
        margin-top: 25px;
    }

    .element-enter {
        opacity: 0;
        transform: translateY(50px);
    }
    .element-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 500ms, transform 400ms ease-out;
    }
}
