import React from 'react';
import {
    gql,
    useLazyQuery,
} from "@apollo/client";
import { useDispatch, useSelector } from 'react-redux';
import { getChatData } from '../store/selectors';
import { saveChatData } from '../store/actions';
import { ChatBlockData, ChatBlockRecord, ChatData, ChatDataVars, ChatFlowRecord, ChatSettingRecord, InputRecord, PriceGroupRecord } from '../types/chat-block';
import { ChatBlockLookup, ChatFlowType, ChatSettings, InputField, MessageTextType, PriceGroup } from '../store/types';
import { useCampaign } from './use-campaign';
import { CHAT_DATA } from '../queries/chat-data';
import { useChatBlocks } from './use-chat-blocks';

export const useChatData = () => {

    const campaignId = useCampaign(process.env.CAMPAIGN_KEY ?? "mothers-day");
    const [fetchChatData, { loading, error, data }] = useLazyQuery<ChatData, ChatDataVars>(gql(CHAT_DATA));

    const chatData = useSelector(getChatData);
    useChatBlocks(chatData?.chatFlow);

    const dispatch = useDispatch();

    const getNextBlock = (node: ChatFlowRecord, list: string[]) => {
        if (!node) return; // that should never happen
        list.push(node.id);
        node.children?.forEach(childNode => {
            getNextBlock(childNode, list);
        });
    };

    const mapChatFlow = (chatFlow: ChatFlowRecord): string[] => {
        // Created a flat list of chat block ids in order
        var retList: string[] = [];
        getNextBlock(chatFlow, retList);
        return retList;
    };

    const mapInputFields = (inputFields: InputRecord[]): InputField[] => {
        return inputFields.map(({ id, title, inputType }) => ({ id, title, inputType })); // Same thing at the moment
    };
    const mapPriceGroups= (priceGroups: PriceGroupRecord[]): PriceGroup[] => {
        return priceGroups.map(({ id, key, position }) => ({ id, key, position })); // Same thing at the moment
    };

    const mapChatSetting = (chatSetting: ChatSettingRecord): ChatSettings => {
        return {
            avatar: chatSetting.avatar,
            header: chatSetting.header,
            delay: chatSetting.delay,
            chatFlowType: chatSetting.chatflowType as ChatFlowType,
            icon: chatSetting.icon,
            headerText: chatSetting.headerText,
            edmBanner:chatSetting.edmBanner
        };
    };

    React.useEffect(() => {
        if (!chatData && campaignId) {
            // console.log('fetching chat data');
            fetchChatData({ variables: { campaignId } });
        }
    }, [chatData, campaignId]);

    React.useEffect(() => {
        if (data) {
            // console.log('saving chat data');
            dispatch(saveChatData(mapChatFlow(data.chatSetting.chatflow), mapInputFields(data.inputFields), mapPriceGroups(data.priceGroups), mapChatSetting(data.chatSetting)));
        }
    }, [data]);

    return chatData;
};