@use "../../styles/imports/utils";
@use "../../styles/imports/variables" as vars;

.landing {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    background-color: vars.$background-colour;
}



.chat-screen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: vars.$background-colour;

    overflow: auto;

    .back {
        position: absolute;
        top: 20px;
        left: vars.$container-padding;
    }

    .chat {
        position: absolute;
        left: 0;
        height: 100%;
        width: 100%;
    }

    &.sticky-header {
        overflow: visible;

        .chat {
            overflow: auto;
        }
    }
}

.chat-blocks {
    padding: 50px vars.$container-padding;

    > * {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 60vh;
        }
    }
}


.chat-screen-enter {
    opacity: 0;
}

.chat-screen-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.chat-screen-exit {
    opacity: 1;
}

.chat-screen-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}