export const SHOPPING_CENTRE = `
query getShoppingCentre($shoppingCentreKey : String) {
  shoppingCentre(filter: {key: {eq: $shoppingCentreKey}})
  {
    id
    title
    key
    emailSender
    emailReplyTo
    edmBanner {
      url
      alt
      focalPoint {
        x
        y
      }
    }
    formAssemblySettings {
      id: formId
      environment
      dbControl
    }
    websiteLink
    storesLink
    openingHoursLink
    blogLink
    logoLink
    facebook
    instragram
    tripadvisor
    address
  }
  }
  `;
