import { createSelector } from 'reselect';
import { utils } from '../services/utils';
import { RootState } from './reducers';
import { ChatFlowType } from './types';

export const getAppState = (store: RootState) => store.app;
export const getProducts = (store: RootState) => store.content?.products;
export const getProductsCount = (store: RootState) => store.content?.productsCount;
export const getStores = (store: RootState) => store.content?.stores;
export const getChatData = (store: RootState) => store.content?.chatData;
export const getCategories = (store: RootState) => store.content?.categories;
export const getCampaign = (store: RootState) => store.content?.campaignId;
export const getPriceGroups = (store: RootState) => store.content?.chatData?.priceGroups?.sort((item1, item2) => item1.position - item2.position);
export const getGlobalCategoriesIds = (store: RootState) => store.content?.categories.filter(category => category.selectAll)?.map(category => category.id);
export const getGiftLists = (store: RootState) => store.app?.giftLists
export const getActiveListId = (store: RootState) => store.app?.activeListId;
export const getUserDetails = (store: RootState) => store.app?.userDetails;
export const getActiveList = (store: RootState) => (store.app?.activeListId && store.app?.giftLists.find(list => list.id === store.app.activeListId));



export const getDefaultIcon = createSelector([getChatData], (chatData) => chatData?.chatSetting?.icon);

export const getDefaultEdmBanner = createSelector([getChatData], (chatData) => chatData?.chatSetting?.edmBanner);

export const getDefaultHeader = createSelector([getChatData], (chatData) => chatData?.chatSetting?.header);

export const getDelay = createSelector([getChatData], (chatData) => chatData?.chatSetting?.delay ?? 3);

export const getChatBlocks = createSelector([getChatData], (chatData) => chatData?.chatBlocks);

export const getIsCategoryBased = createSelector([getChatData], (chatData) => chatData?.chatSetting?.chatFlowType === ChatFlowType.CategoryBased);

export const getShoppingCentre = (store: RootState) => store.content?.shoppingCentre;

export const getPersona = createSelector([getActiveList, getCategories], (list, categories) => {
    if (!list?.category) return null;
    let category = categories?.find(x => x.id === list.category);
    if (!category) return null;
    return { personaName: category.personaName, personaAvatar: category.personaAvatar };
});

export const createGetIsProductSaved = (giftListId: string, productId: string) => createSelector([getGiftLists], (giftLists) => {
    let giftList = giftLists.find(list => list.id === giftListId);
    return giftList?.products?.includes(productId);
});

export const createGetRetailerLogo = (retailerId: string) => createSelector([getStores], (stores) => {
    return stores?.find(store => store.retailerId === retailerId)?.logo;
});

export const getValidCategories = createSelector([getProducts, getCategories], (products, categories) => {
    if (!products?.length) return null;
    const ids = utils.getDistinct(utils.getFlat(products.map(({ categories }) => categories)));
    return categories?.filter(({ id, selectAll }) => selectAll || ids.includes(id));
});

export const getGiftListsFull = createSelector([getProducts, getCategories, getGiftLists], (products, categories, giftLists) => {
    return giftLists.map(list => ({
        ...list,
        products: list.products.map(id => products?.find(product => product.id === id)).filter(product => !!product),
        category: categories?.find(category => category.id === list.category)
    }));
});

// If the products doesn't have any values for the serach, or the skip is set and is higher than the product list
export const getResults = (skip: number = 0, take?: number, otherCategories: boolean = false, filterByTags?: boolean) => createSelector([getProducts, getGlobalCategoriesIds, getActiveList, getIsCategoryBased , getPriceGroups], (products, allCategories, list, isCategoryBased, priceGroups) => {
     var results = isCategoryBased? products?.filter(({ categories }) => {
        return (!otherCategories) ?
            (!categories?.length ||                    // product has no category OR
                allCategories?.includes(list.category) ||    // current category is a inclusive category (works as select all) OR                
                categories.includes(list.category))
            : (categories?.length > 0 &&
                !allCategories?.includes(list.category) &&
                !categories.includes(list.category));          // product matches selected category
    }):
    products;

    // Filter by price groups
    if (list?.priceGroups?.length && !isCategoryBased) {
         var filterList =  list.priceGroups;
        if (otherCategories && list.priceGroups.length < priceGroups.length){ // consider situation when the list has the same amount of items / none stop loop

            // find the largest price group in the selected list
            var currentList =list.priceGroups?.map(pg => priceGroups?.findIndex(item => item.id === pg)); // get the index of the selected pricegroups
            var currentIndex =Math.max( ... list.priceGroups?.map(pg => priceGroups?.findIndex(item => item.id === pg))); // get the max index of the selected ones
            var incremental = currentIndex== priceGroups.length-1 ? -1 :1;
            while (currentList.includes(currentIndex) && currentIndex>0 && currentIndex < priceGroups.length){ // trying to find the first next that does not exist in the selected list
                incremental = currentIndex== priceGroups.length-1 ? -1 : incremental; // if we get to the end of the list we need to go back
                currentIndex = currentIndex + (incremental * 1)
            }
            filterList = (currentIndex<=0 || currentIndex >= priceGroups.length || currentList.includes(currentIndex))?[] : [ priceGroups[currentIndex].id];
        }

        results = results?.filter(product => product.priceGroups.find(({ id }) => filterList.includes(id)));
    }

    if (filterByTags && list?.tags?.length) {
        results = results.filter(({ tags }) => {
            return tags?.some(tag => list.tags.includes(tag));
        });
    }

    results = results.sort((a, b) => {
        if (a.featured === b.featured) {
            // sort by tags
            let matchingTagsA = (a.tags || []).filter(tag => list.tags.includes(tag)).length;
            let matchingTagsB = (b.tags || []).filter(tag => list.tags.includes(tag)).length;
            return matchingTagsB - matchingTagsA; // Highest number first
        }
        // True value for featured comes first
        return a.featured ? -1 : 1;
    });

    let end = take ? skip + take : results.length;
    return results?.slice(skip, end);
});


export const createGetInputValue = (inputId: string) => createSelector([getActiveList, getShoppingCentre, getChatData], (list, shoppingCentre, chatData) => {
    return (!list || !list.inputs[inputId]) ?
        (chatData?.inputFields.find(input => input.inputType.toLowerCase() == "shoppingcentre" && inputId == input.id) ? shoppingCentre?.title : "")
        : list?.inputs[inputId];
});

export const getInputByType = (inputType: string) => createSelector([getChatData], (chatData) => {
    return chatData?.inputFields.find(input => input.inputType.toLowerCase() == inputType.toLowerCase());
});
