export const STORES = `
query getStores($shoppingCentreId : ItemId) {
  stores: allStores(first: 100 ,filter: {shoppingCentre: {eq: $shoppingCentreId}}) {
    id
    displayName
    url
    retailer{
      id
      title
      url
      logo{
        url
        alt
        focalPoint{
          x
          y
        }
      }
    }
  }
  storesSecond: allStores(skip:100,first: 100 ,filter: {shoppingCentre: {eq: $shoppingCentreId}}) {
    id
    displayName
    url
    retailer{
      id
      title
      url
      logo{
        url
        alt
        focalPoint{
          x
          y
        }
      }
    }
  }
}
  `;
