import React from 'react';
import { useSelector } from 'react-redux';
import { createGetInputValue } from '../../store/selectors';
import { MessageInlineItem, MessageSpan, MessageText, MessageTextType } from '../../store/types';
import * as classes from './message.module.scss';

export const Message: React.FC<{ style?: React.CSSProperties, textItems: MessageText[], typing: boolean }> = ({ style, textItems, typing }) => {

    return (
        <div className={classes.message} style={style}>
            {typing ?
                <React.Fragment>
                    {[...Array(3)].map((item, index) => <span key={`message-dot-${index}`} className={classes.dot}></span>)}
                </React.Fragment> :
                <React.Fragment>
                    {textItems.map((item, index) => {
                        if (item.type === MessageTextType.Span) return <span key={`message-item=${index}`}>{(item as MessageSpan).value}</span>
                        if (item.type === MessageTextType.InlineItem) return <MessageInlineItem key={`message-item=${index}`} inputId={(item as MessageInlineItem).item} />
                    })}
                </React.Fragment>
            }
        </div>
    );
};

const MessageInlineItem: React.FC<{ inputId: string }> = ({ inputId }) => {
    const getInputValue = createGetInputValue(inputId);
    const inputValue = useSelector(getInputValue);
    return (
        <span>{inputValue}</span>
    );
};