@use "../../styles/imports/utils";
@use "../../styles/imports/variables" as vars;

.persona-header {
    height: 104px;
    position: relative;
    overflow: hidden;
    transition: height 2s ease;

    .background {
        @include utils.background-image();
        background-color: vars.$grey; // Just a backup for no image
        height: 100%;
    }

    .title-container {
        position: absolute;
        bottom: 0;
        min-height: 152px;
        left: 50%;
        transform: scale(0.66);
        transform-origin: bottom;
        transition: transform 2s ease;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .title {
        color: vars.$cream;
        text-align: center;
        margin: 0;
        font-size: 51px;
        line-height: 44px;

        .title-small {
            font-size: 20px;
            line-height: 17px;
        }

        span {
            display: block;
        }
    }

    &.persona-header-maximised {
        height: 472px;

        .gradient {
            display: none;
        }

        .title-container {
            width: 100%;
            transform: translateX(-50%) scale(1);
        }
    }
}
