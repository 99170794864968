declare const window: any;

class Utils {
    getDistinct(list: {}[]): {}[] {
        return list?.filter((v, i, a) => a.indexOf(v) === i);
    }
    getFlat(list: {}[][]): {}[] {
        return [].concat.apply([], list);
    }
    addQueryStringParameter(url: string, paramName: string, paramValue: string) {
        let paramPrefix = url.indexOf('?') !== -1 ? "&" : "?";
        return `${url}${paramPrefix}${paramName}=${paramValue}`;
    }
    addQueryStringParams(url: string, params: { name: string, value: string }[]) {
        if (!params?.length || !url) return url;
        let prefix = url.indexOf('?') !== -1 ? "&" : "?";
        let queryString = params.map(({ name, value }) => `${name}=${value}`).join("&")
        return `${url}${prefix}${queryString}`;
    }
    createGtmEvent(event: string, data: any) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event,
            ...data
        });
    }
}

export const utils = new Utils();