@use "sass:math";
@use "../../styles/imports/utils";

.productDetails {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    background: white;
    height: 100%;

    .image {
        width: 100%;
        height: 40vh;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
    }

    .logo {
        position: absolute;
        left: 20px;
        top: 20px;
    }

    .save {
        position: absolute;
        right: 20px;
        bottom: 20px;
    }

    .details {
        min-height: 30vh;
        padding: 18px;
    }

    .title {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}
