@use '../../styles/imports/utils';

.avatar-heading {
    text-align: center;

    .avatar {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        @include utils.background-image();
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
    }

    .heading {
        margin: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
