import React from 'react';
import { ChatBlock } from '../chat-block/chat-block';
import * as classes from './chat.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveList, getChatBlocks, getPersona } from '../../store/selectors';
import { addNewList, removeTags, saveChatHistory } from '../../store/actions';
import { PersonaHeader } from '../persona-header/persona-header';
import { CSSTransition } from 'react-transition-group';
import { IconTextButton } from '../icon-text-button/icon-text-button';
import Chevron from '../../assets/svgs/chevron-left.svg';
import { useChatData } from '../../hooks/use-chat-data';
import { Screen } from '../screen/screen';
import { SendListForm } from '../send-list-form/send-list-form';
import { ChatBlockOptionsElement, ChatFlowType, ElementType } from '../../store/types';
import { Header } from '../header/header';

export const Chat: React.FC = () => {

    const chatData = useChatData();
    const { id: activeListId, chatHistory } = useSelector(getActiveList);
    const persona = useSelector(getPersona);
    const dispatch = useDispatch();
    const ref = React.useRef<HTMLDivElement>();
    const [isHeaderSticky, setIsHeaderSticky] = React.useState<boolean>();
    const [isFormOpen, setIsFormOpen] = React.useState<boolean>();


    const containerRef = React.useCallback(node => {
        if (node !== null) {
            let hasScroll = node.scrollHeight > node.clientHeight;
            setTimeout(() => {
                setIsHeaderSticky(hasScroll);
            }, 1000);
        }
    }, []);

    React.useEffect(() => {
        if (chatData) {
            // TODO: Maybe create the empty list - instead of in store.ts - activeListId would be null 

            // if no history, add the first chat block from the flow
            if (!chatHistory?.length) {
                dispatch(saveChatHistory(activeListId, [{ chatBlockId: chatData.chatFlow[0] }]));
            }
        }
    }, [chatData, activeListId, chatHistory]);

    React.useEffect(() => {
        if (ref.current) {
            // Scroll to the bottom of the container whenever chat blocks are added
            // But only if last block was not results.
            let lastChatBlock = chatData?.chatBlocks?.[chatHistory[chatHistory.length - 1].chatBlockId];
            if (lastChatBlock?.element?.type !== ElementType.Results) {
                ref.current.scroll(0, ref.current.scrollHeight);
            }
        }

    }, [chatHistory]);

    if (!chatData || !chatData?.chatBlocks || !chatHistory?.length) return <div className="loading">Loading...</div>;
    const { chatBlocks, chatFlow, chatSetting } = chatData;

    const hasLandingPage = chatSetting.chatFlowType == ChatFlowType.CategoryBased;
    const landingBlockId = chatHistory[0].chatBlockId;
    const landingBlock = chatBlocks[landingBlockId];


    const findNextBlock = (blockId: string) => {
        // get the index of this block within the chat flow
        let indexOfCurrentBlock = chatFlow.indexOf(blockId);
        return chatFlow.length - 1 > indexOfCurrentBlock ? chatFlow[indexOfCurrentBlock + 1] : null;
    };

    const handleChatBlockNext = (index: number, blockId: string, nextBlockId?: string, answerId?: string) => {
        // Remove the tags that were set on questions after this block (the current block is already handled in the options-element component)
        let selectedOptions = chatHistory.slice(index + 1).map(x => {
            let chatBlock = chatBlocks[x.chatBlockId];
            if (x.answerId && chatBlock?.element?.type === ElementType.Option) {
                return (chatBlock.element as ChatBlockOptionsElement).options?.find(y => y.id === x.answerId);
            }
            return null;
        }).filter(x => !!x);
        let tagsToClear = [].concat.apply([], selectedOptions.map(x => (x?.tags || []))).map(x => x.id);
        if (tagsToClear.length) {
            dispatch(removeTags(activeListId, tagsToClear));
        }

        // Remove everything after this block and save the answer.
        let newHistory = [...chatHistory.slice(0, index), { chatBlockId: blockId, answerId }];


        // Go to the specified block or the next block in the flow.
        let nextBlock = nextBlockId || findNextBlock(blockId);
        if (nextBlock) {
            newHistory.push({ chatBlockId: nextBlock });
        }

        dispatch(saveChatHistory(activeListId, newHistory));
    };

    const handleBack = (newList?: boolean) => {
        if (newList) {
            dispatch(addNewList());
            return;
        }
        // go back to first chat block
        dispatch(saveChatHistory(activeListId, []));
        setIsHeaderSticky(false);
    };

    const handleSave = () => {
        setIsFormOpen(true);
    };


    return (
        <React.Fragment>

            {!!landingBlock &&
                <div className={classes.landing}>
                    <div className={classes['chat-blocks']}>
                        <ChatBlock
                            messages={landingBlock.messages}
                            avatar={chatSetting?.avatar}
                            element={landingBlock.element}
                            onNext={(action, value) => handleChatBlockNext(0, landingBlockId, action, value)}
                            onBack={(action) => handleBack(action)}
                            onSave={handleSave}
                            delay={chatSetting?.delay} />
                    </div>
                </div>
            }

            <CSSTransition
                in={chatHistory.length > 1}
                mountOnEnter
                unmountOnExit
                timeout={600}
                classNames={{
                    enter: classes['chat-screen-enter'],
                    enterActive: classes['chat-screen-enter-active'],
                    exit: classes['chat-screen-exit'],
                    exitActive: classes['chat-screen-exit-active'],
                }}
            >
                <div className={classes['chat-screen'] + (isHeaderSticky ? ` ${classes['sticky-header']}` : '')} ref={containerRef}>
                    {chatSetting.chatFlowType !== ChatFlowType.CategoryBased && <Header title={chatSetting.headerText} img={chatSetting.header} />}
                    {chatSetting.chatFlowType === ChatFlowType.CategoryBased && !!persona &&
                        <PersonaHeader minimised={isHeaderSticky} title={persona.personaName} avatar={persona.personaAvatar} />
                    }
                    {chatSetting.chatFlowType === ChatFlowType.CategoryBased &&
                        <IconTextButton className={classes.back} onClick={() => handleBack()}>
                            <Chevron /><small>Back</small>
                        </IconTextButton>
                    }
                    <div className={classes.chat} ref={ref}>
                        <div className={classes['chat-blocks']}>
                            {chatHistory.slice(1).map(({ chatBlockId, answerId }, index) => {
                                let chatBlock = chatBlocks[chatBlockId];
                                if (!chatBlock) return null; // ID doesn't match when we change Dato environments
                                return (
                                    <ChatBlock
                                        key={`chat-block-${index}`}
                                        avatar={persona?.personaAvatar || chatSetting?.avatar}
                                        messages={chatBlock.messages}
                                        element={chatBlock.element}
                                        answerId={answerId}
                                        instant={index + 1 < chatHistory.length - 1}
                                        onNext={(action, value) => handleChatBlockNext(index + 1, chatBlockId, action, value)}
                                        onBack={(action) => handleBack(action)}
                                        onSave={handleSave}
                                        delay={chatSetting?.delay} />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </CSSTransition>

            <Screen show={isFormOpen} onBack={() => setIsFormOpen(false)}>
                <SendListForm onCancel={() => setIsFormOpen(false)} />
            </Screen>

        </React.Fragment>
    );
};