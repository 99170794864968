@use "../../styles/imports/variables" as vars;
@use "../../styles/imports/utils";

.results {
    .filter {
        position: relative;
        margin-bottom: 27px;

        select {
            border: 1px solid vars.$grey;
            border-radius: 10px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.07);
            color: vars.$brown;
            padding: 12px 16px;
            padding-right: 45px;
            width: 100%;

            -moz-appearance: none; /* Firefox */
            -webkit-appearance: none; /* Safari and Chrome */
            appearance: none;

            &:focus {
                border: 2px solid vars.$hero-colour-1;
                padding: 11px 15px;
                outline: none;
            }
        }

        .chevron {
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);

            background-color: vars.$light-pink;
            color: vars.$brown;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: none;
            transition: color 0.4s, background-color 0.4s;
        }

        &:hover {
            .chevron {
                color: darken(vars.$brown, 2%);
                background-color: darken(vars.$light-pink, 2%);
            }
        }
    }

    .productsGrid {
        width: 100%;
        clear: both;

        &:after {
            clear: both;
            content: "";
            display: table;
        }

        > * {
            float: left;
            height: 0;
            &:nth-child(4n - 3) {
                width: calc(50% - 5px);
                padding-top: 70%;

                &:last-child {
                    width: 100%;
                }
            }
            &:nth-child(4n - 2) {
                width: calc(50% - 5px);
                padding-top: calc(35% - 5px);
                margin-left: 10px;

                &:last-child {
                    padding-top: 70%;
                }
            }
            &:nth-child(4n - 1) {
                width: calc(50% - 5px);
                padding-top: calc(35% - 5px);
                margin-left: 10px;
                margin-top: 10px;
            }
            &:nth-child(4n) {
                width: 100%;
                padding-top: 35%;
                margin-top: 10px;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            position: relative;
            > * {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
        }
    }

    .no-results {
        text-align: center;
        font-size: 12px;
        opacity: 0.7;
        font-weight: 500;
    }
}