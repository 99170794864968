import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { ProductCount, ProductCountVars } from "../types/product";
import {  PRODUCTS_COUNT } from "../queries/products";
import { saveProductsCount } from "../store/actions";
export const useProductsCount = ({retailerIds,storeIds}) => {

  const [fetchProductsCount, { data }] = useLazyQuery<ProductCount, ProductCountVars>(gql(PRODUCTS_COUNT)) ;
  const dispatch = useDispatch();

 

  // Fetch the products when we know the retailers and stores
  React.useEffect(() => {
    if (retailerIds?.length && storeIds?.length) {

      fetchProductsCount({ variables: { retailerIds, storeIds } });
      
    }
  }, [retailerIds, storeIds]);

  // Put the products into the redux state
  React.useEffect(() => {
    if (data?.items) {
      dispatch(
        saveProductsCount(data.items?.count ?? 0)
      );
    }
  }, [data]);

  return data?.items?.count;
};
