import React from 'react';
import * as classes from './product-details.module.scss';
import { HeartButton } from '../heart-button/heart-button';
import { Logo } from '../logo/logo';
import { Product } from '../../store/types';

export const ProductDetails: React.FC<{ product: Product }> = ({ product }) => {
    const { name, price, description, image, retailer } = product;

    return (
        <div className={classes.productDetails}>
            <div className={classes.image} style={{ backgroundImage: `url(${image?.url})` }}>
                {!!retailer?.logo && <Logo className={classes.logo} logo={retailer.logo} />}
                <HeartButton className={classes.save} productId={product.id} />
            </div>
            <div className={classes.details}>
                <small>{retailer?.title}</small>
                <h3 className={classes.title}>{name}<span>{price}</span></h3>
                <small>{description}</small>
            </div>
        </div>
    );
};