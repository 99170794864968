@use "../../styles/imports/utils";
@use "../../styles/imports/variables" as vars;

.screen {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: white;
    overflow: auto;
    z-index: 10;

    .screen-inner {
        position: relative;
        height: 100%;
    }

    .back {
        position: absolute;
        top: 20px;
        left: vars.$container-padding;
    }
}

.screen-enter {
    opacity: 0;
}

.screen-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.screen-exit {
    opacity: 1;
}

.screen-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}
