import React from 'react';
import { Avatar } from '../avatar/avatar';
import { Message } from '../message/message';
import * as classes from './chat-block.module.scss';
import { TransitionGroup, Transition, CSSTransition } from 'react-transition-group';
import { Element } from '../element/element';
import { ChatBlockElement, ChatBlockMessage, ElementType } from '../../store/types';
import { Img } from '../../types/img';

type ChatBlockProps = {
    avatar: Img;
    messages: ChatBlockMessage[];
    element: ChatBlockElement;
    answerId?: string;
    onNext: (action?: string, value?: string) => void;
    onBack: (newList?: boolean) => void;
    onSave: () => void;
    instant?: boolean;
    delay?: number;
};

export const ChatBlock: React.FC<ChatBlockProps> = ({ avatar, messages, element, answerId, onNext, onBack, onSave, instant, delay = 3000 }) => {
    const [activeMessage, setActiveMessage] = React.useState<number>(instant ? messages.length - 1 : 0);
    const [showElement, setShowElement] = React.useState<boolean>(instant && !!element);
    const transitionDuration = instant ? 0 : delay * 0.7;

    const defaultStyle = {
        transition: `opacity 300ms ease-in-out`,
        opacity: 0,
    }

    const transitionStyles = {
        entering: { opacity: 1 },
        entered: { opacity: 1 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 },
    };

    React.useEffect(() => {
        if (instant) return;

        const timeout = setTimeout(() => {
            if (messages.length > activeMessage + 1) {
                setActiveMessage(activeMessage + 1);
            }
            else if (element && !showElement) {
                setShowElement(true);
            }
            else {
                if (element?.type !== ElementType.Results) { // results has it's own next trigger when scroll to bottom
                    onNext();
                }
            }
        }, messages.length ? delay : 0);

        return () => {
            clearTimeout(timeout);
        };
    }, [activeMessage, messages, element, instant]);

    return (
        <div className={classes.chatBlock}>
            {!!messages.length && <div className={classes.messages}>
                <div className={classes.messageSender}>
                    <Avatar img={avatar} />
                </div>
                <TransitionGroup className={classes['message-items']}>
                    {messages.slice(0, activeMessage + 1).map((message, index) => {
                        return (
                            <Transition timeout={transitionDuration} appear key={`message-${index}`}>
                                {state => (
                                    <Message style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state]
                                    }} textItems={message?.text} typing={state === 'entering'} />
                                )}
                            </Transition>
                        );
                    })}
                </TransitionGroup>
            </div>
            }
            <CSSTransition
                mountOnEnter
                unmountOnExit
                in={showElement}
                timeout={500}
                classNames={{
                    enter: classes['element-enter'],
                    enterActive: classes['element-enter-active']
                }}>
                <div className={classes.element}>
                    <Element element={element} answerId={answerId} isActive={!instant} onNext={onNext} onBack={onBack} onSave={onSave} />
                </div>
            </CSSTransition>
        </div>
    );
};