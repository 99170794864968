.drawer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(black, 0.4);
    overflow: auto;
    z-index: 10;

    .drawer-inner {
        position: relative;
        padding-top: 50px;
        height: 100%;
    }

    .close {
        position: absolute;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.drawer-enter {
    opacity: 0;
    .drawer-inner {
        transform: translateY(100%);
    }
    .close {
        opacity: 0;
    }
}
.drawer-enter-active {
    opacity: 1;
    .drawer-inner {
        transform: translateY(0);
        transition: transform 300ms;
    }
    .close {
        opacity: 1;
        transition: opacity 300ms 300ms;
    }
    transition: opacity 300ms;
}
.drawer-exit {
    opacity: 1;
    .drawer-inner {
        transform: translateY(0);
    }
    .close {
        opacity: 1;
    }
}
.drawer-exit-active {
    opacity: 0;
    .drawer-inner {
        transform: translateY(100%);
        transition: transform 300ms;
    }
    .close {
        opacity: 0;
        transition: opacity 300ms;
    }
    transition: opacity 300ms;
}
