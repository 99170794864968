import React from 'react';
import { utils } from '../../services/utils';
import { Img } from '../../types/img';
import * as classes from './avatar.module.scss';

export const Avatar: React.FC<{ img: Img }> = ({ img }) => {
    const croppedImg = React.useMemo(() => utils.addQueryStringParams(img?.url, [
        { name: 'h', value: '38' },
        { name: 'w', value: '38' },
        { name: 'fit', value: 'crop' },
        { name: 'crop', value: 'focalpoint' },
        { name: 'fp-x', value: `${img?.focalPoint?.x || 0.5}` },
        { name: 'fp-y', value: `${img?.focalPoint?.y || 0.5}` },
    ]), [img]);

    return (
        <div className={classes.avatar} style={{ backgroundImage: `url(${croppedImg})` }} />
    );
};