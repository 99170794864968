import React from 'react';
import * as classes from './heart-button.module.scss';
import HeartIcon from '../../assets/svgs/heart.svg';
import { createGetIsProductSaved, getActiveListId } from '../../store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { removeProduct, addProduct } from '../../store/actions';
import { utils } from '../../services/utils';

export const HeartButton: React.FC<{ productId: string, className?: string }> = ({ productId, className }) => {
    const giftListId = useSelector(getActiveListId);
    const getIsSaved = React.useMemo(() => createGetIsProductSaved(giftListId, productId), [giftListId, productId]);
    const isSaved = useSelector(getIsSaved);

    const dispatch = useDispatch();

    const handleClick = () => {
        utils.createGtmEvent((isSaved ? 'product-removed' : 'product-saved'), { productId });
        if (!giftListId) return; // Make sure we have a list to save the product to
        dispatch(isSaved ? removeProduct(giftListId, productId) : addProduct(giftListId, productId));
    };

    return (
        <button onClick={handleClick} className={classes['heart-button'] + (className ? ` ${className}` : '') + (isSaved ? ` ${classes.active}` : '')}>
            <HeartIcon />
        </button>
    );
};