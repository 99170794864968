import React from 'react';
import * as classes from './google-sign-in.module.scss';
import { GoogleLogin } from 'react-google-login';
import GoogleIcon from '../../assets/svgs/google.svg';

export const GoogleSignIn: React.FC<{ onSignIn: (firstName: string, lastName: string, email: string) => void }> = ({ onSignIn }) => {
    const onSuccess = (response) => {
        onSignIn(response?.profileObj?.givenName, response?.profileObj?.familyName, response?.profileObj?.email);
    };

    const onFailure = (response) => {
        console.log(response);
    };

    return (
        <GoogleLogin
            clientId={process.env.GOOGLE_CLIENT_ID}
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={'single_host_origin'}
            render={renderProps => (
                <button className={classes["google-sign-in"]} onClick={renderProps.onClick} disabled={renderProps.disabled}>
                    <GoogleIcon />
                    <span>Sign in with Google</span>
                </button>
            )}
        />
    );
};