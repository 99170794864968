import React from 'react';
import {
    gql,
    useLazyQuery,
} from "@apollo/client";
import { useDispatch, useSelector } from 'react-redux';
import { getCampaign } from '../store/selectors';
import { saveCampaign } from '../store/actions';
import { CAMPAIGN } from '../queries/campaign';
import { CampaignData, CampaignVars } from '../types/campaign';

export const useCampaign = (campaignKey: string) => {
    const [fetchCampaign, { loading, error, data }] = useLazyQuery<CampaignData, CampaignVars>(gql(CAMPAIGN));
    const campaignId = useSelector(getCampaign);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!campaignId && campaignKey) {
         fetchCampaign({ variables: { key: campaignKey } });
        }
      }, [campaignId, campaignKey]);
    
      React.useEffect(() => {
        if (data?.campaign) {
          dispatch(saveCampaign(data.campaign?.id));
        }
      }, [data]);
    
      return campaignId;
};