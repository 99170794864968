import React from 'react';
import * as classes from './screen.module.scss';
import { CSSTransition } from 'react-transition-group';
import { Portal } from '../portal/portal';
import Chevron from '../../assets/svgs/chevron-left.svg';
import { IconTextButton } from '../icon-text-button/icon-text-button';

export const Screen: React.FC<{ show: boolean, onBack: () => void, children: React.ReactNode }> = ({ show, onBack, children }) => {
    return (
        <CSSTransition
            in={show}
            timeout={600}
            classNames={{
                enter: classes['screen-enter'],
                enterActive: classes['screen-enter-active'],
                exit: classes['screen-exit'],
                exitActive: classes['screen-exit-active'],
            }}
            unmountOnExit>
            <Portal portalId="gift-guide-screen-portal">
                <div className={classes.screen}>
                    <div className={classes['screen-inner']}>
                        {children}
                        <IconTextButton className={classes.back} onClick={onBack}>
                            <Chevron /><small>Back</small>
                        </IconTextButton>
                    </div>
                </div>
            </Portal>
        </CSSTransition>
    );
};