
class StateLoader {
    key = process.env.CAMPAIGN_KEY;

    loadAppState() {
        try {
            let serializedState = localStorage.getItem(this.key);

            if (serializedState === null) {
                return this.initializeAppState();
            }

            return JSON.parse(serializedState);
        }
        catch (err) {
            return this.initializeAppState();
        }
    }

    saveAppState(state) {
        try {
            let serializedState = JSON.stringify(state);
            localStorage.setItem(this.key, serializedState);
        }
        catch (err) {
        }
    }

    initializeAppState() {
        return {
            giftLists: [],
            activeListId: null,
            userDetails: null
        };
    }
}

export const stateLoader = new StateLoader();