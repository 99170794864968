import React from 'react';
import * as classes from './product-tile.module.scss';
import { ProductDetails } from '../product-details/product-details';
import { Modal } from '../modal/modal';
import { HeartButton } from '../heart-button/heart-button';
import { Logo } from '../logo/logo';
import { Product } from '../../store/types';
import { utils } from '../../services/utils';

export const ProductTile: React.FC<{ product: Product }> = ({ product }) => {
    const { image, retailer } = product;
    const [showDetails, setShowDetails] = React.useState<boolean>();

    // TODO: put this in next time after testing
    // const shrunkImg = React.useMemo(() => utils.addQueryStringParams(image?.url, [
    //     { name: 'w', value: '420' },
    // ]), [image]);

    const handleProductClick = () => {
        utils.createGtmEvent('product-details-opened', { productId: product.id });
        setShowDetails(true);
    };

    return (
        <React.Fragment>
            <div className={classes.productTile}>
                <div className={classes['large-clickable-area']} onClick={handleProductClick}>
                    <div className={classes.image} style={{ backgroundImage: `url(${image?.url})` }} />
                    {!!retailer?.logo && <Logo className={classes.logo} logo={retailer.logo} />}
                </div>
                <HeartButton className={classes.save} productId={product.id} />
            </div>
            <Modal show={showDetails} onClose={() => setShowDetails(false)}>
                <ProductDetails product={product} />
            </Modal>
        </React.Fragment>
    );
};